import React from "react";
import { Select } from "antd";
import { getCountries } from "../../assets/countries";
import FakeLabel from "../input/select";
import { useMediaQuery } from "react-responsive";
import useSize from "../../hooks/useSize";
import { isOfflineUser } from "../../offline";
const countries = getCountries();
export default function CountrySelect({ value, setValue, title }) {
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();

  if (isOfflineUser()) {
    title = "Your Country";
  }
  return (
    <>
      <FakeLabel
        title={title || "Country"}
        elem={
          <Select
            placeholder={title || "Select Country"}
            value={value}
            onChange={setValue}
            showSearch
            dropdownStyle={{ width: isExtraSmall ? 160 : 400 }} // Change this to the desired width
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {countries.map((country) => (
              <Select.Option key={country} value={country}>
                {country}
              </Select.Option>
            ))}
          </Select>
        }
      />
    </>
  );
}
