import { MenuOutlined } from "@ant-design/icons";
import { Layout, Modal, Typography } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import useSize from "../../hooks/useSize";
import { AppTitle, Logo } from "../../styles/core-components";
import BackLink from "../Link/BackLink";
import { isOfflineUser } from "../../offline";
const { Header } = Layout;

const AppHeader = ({ setVisible, visible, colorBgContainer, loading }) => {
  const { isMobile: smallToTabView } = useSize();
  // console.clear();
  const app_data = {
    title: <AppTitle loading={loading} />,
    logo: <Logo className={"heading-logo"} />,
  };

  const { isExtraSmall, isSmall, isMobile, isMedium, isLarge, isExtraLarge } =
    useSize();
  return (
    <Header
      style={{
        padding: 10,
        background: colorBgContainer,
        display: "flex",
        alignContent: "center",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {smallToTabView && (
        <MenuOutlined
          onClick={() => {
            console.log(visible);
            setVisible(true);
          }}
        />
      )}
      <div style={{ display: isMobile || loading ? "none" : "block" }}>
        <BackLink />
      </div>
      {isOfflineUser() ? (
        ""
      ) : (
        <Typography.Title
          style={{
            fontSize: isExtraSmall ? "18px" : "24px",
            marginBottom: "0.8em",
            margin: "0 auto",
          }}
        >
          {app_data.title}
        </Typography.Title>
      )}
      <div
        onClick={() =>
          Modal.info({
            title: "Carbon Footprint LTD",
            content: (
              <div>
                <p>SustraxMX is developed by Carbon Footprint Ltd UK.</p>
                <p>
                  For more information, visit:{" "}
                  <a
                    href="https://www.carbonfootprint.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.carbonfootprint.com
                  </a>
                </p>
                <p>
                  Email:{" "}
                  <a href="mailto:info@carbonfootprint.com">
                    info@carbonfootprint.com
                  </a>
                </p>
              </div>
            ),
          })
        }
        style={{
          height: isExtraSmall ? "80%" : isSmall ? "90%" : "100%",
          display: "flex",
          alignItems: "center",
          gap: 10,
          cursor: "pointer", // Added cursor pointer for clickable effect
        }}
      >
        {app_data.logo}
      </div>
    </Header>
  );
};

export default AppHeader;
