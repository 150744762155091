import React, { useEffect } from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import { findFormText } from "../../../helper/attribute";
import { findForm } from "../../../static/formRoutes";
import { filterFactors, getFactors } from "../../../helper/emissionfactors";
import { DownloadOutlined, PercentageOutlined } from "@ant-design/icons";
import paper_weights from "../../../assets/paper_weights.json";
import currencies from "../../../assets/currencies.json";
import { Button } from "antd";
import { genPut } from "../helper/create";
import AntDrop from "../../../components/input/antddropdown";
import { isOfflineUser } from "../../../offline";

export const ExcelExport = (props) => {
  const handleOnChange = (name) => {
    let config = {};
    if (name.includes("American")) {
      config = { format: "NAICS (American)" };
    } else if (name.includes("DEFRA")) {
      config = { format: "SIC (UK)", sic_uk: "DEFRA" };
    } else if (name.includes("ONS")) {
      config = { format: "SIC (UK)", sic_uk: "ONS" };
    }
    props.downloadTemplate({ ...props.form, ...config }, config);
  };
  return (
    <AntDrop
      options={["NAICS (American)", "SIC (UK)- DEFRA", "SIC (UK)- ONS"]}
      onChange={handleOnChange}
    >
      <Button type="link">
        Download Template <DownloadOutlined />
      </Button>
    </AntDrop>
  );
};

export const INPUTS = (data, setData) => {
  const form = findForm("spending");
  return [
    {
      elem: DateInput,
      key: "date",
    },
    {
      elem: SelectSearch,
      key: "format",
      options: ["SIC (UK)", "NAICS (American)"],
      set: { sic_uk: null, product: null },
      excelShow: false,
      hidden: isOfflineUser(),
    },
    {
      elem: SelectSearch,
      key: "sic_uk",
      options: ["DEFRA", "ONS"],
      hidden: data.format !== "SIC (UK)" || isOfflineUser(),
      set: { product: null },
      excelShow: false,
    },
    {
      elem: SelectSearch,
      key: "q1",
      options: ["Yes", "No"],
      hidden: isOfflineUser(),
    },
    {
      elem: SelectSearch,
      key: "product",
      options: getFactors(data.format === "SIC (UK)" ? data.sic_uk : "EPA").map(
        (f) => (isOfflineUser() ? f.offline_name : f.product)
      ),

      dropdownMatchSelectWidth: 600,
      hidden: !data.format || (data.format === "SIC (UK)" && !data.sic_uk),
    },
    {
      elem: SelectSearch,
      key: "currency",
      options: currencies.map((c) => c.name + "- " + c.title),
    },
    {
      elem: NumberInput,
      uom: form.findCurrency(data.currency)?.symbol,
      key: "spend",
    },
  ];
};

export default function Spending({ data, setData }) {
  const formname = data.name;
  useEffect(() => {
    if (isOfflineUser()) {
      setData({ ...data, q1: "No", sic_uk: "DEFRA", format: "SIC (UK)" });
    } else {
      setData({ ...data });
    }
  }, []);
  return (
    <div>
      {INPUTS(data, setData).map((input) =>
        genPut(input, formname, data, setData)
      )}
    </div>
  );
}
