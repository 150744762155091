import { BulbOutlined, RightOutlined, WarningOutlined } from "@ant-design/icons";
import { Empty, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import worker from "workerize-loader!./workers/year"; // eslint-disable-line import/no-webpack-loader-syntax
import SimpleSelect from "../../../components/select/simpleselect";

import { designations, filterResultsByName, findCompanies, findCompany, findSite, findSitesInCompanies, getCurrentUserDesignation, openSites } from "../../../helper/attribute";
import { getCurrentYear } from "../../../helper/time";
import { beautifulObjectNumValues } from "../../../helper/uimaker";
import { removeDuplicates } from "../../../helper/wodash";
import { selectResults } from "../../../redux/features/appSlice";
import { routePath } from "../../../static/routes.static";
import Boxed from "./comp/boxed";
import ExportButton from "./comp/exportbutton";
import Heading from "./comp/heading";
import Options from "./comp/options";
import { GetScopeOffsetBox, configDataSource, createGraphComponent, createcols, tableProps } from "./config";

import { T1 } from "../../../appconfig/texts";
import { scrollToElementWithId } from "../../../helper/dom";
import { getAllParamsFromLocation } from "../../../helper/loc";
import { getCurrentPlan, isSustraxLite, plans } from "../../../helper/plans";
import "../../../styles/responsive.css";
import InLineCards from "./comp/inlineCards";
import "./style.css";
import { getAnalysis } from "./workers/year";
import GPTRecommendations from "./comp/gptRecommendations";
import SkeletonBarChart from "./charts/SkeletonBarChart";
import store from "../../../redux/store";
import { openSite } from "../../../firebase/site/close";
import ResultsTableDescription, { DisplayResultsTableData } from "./other/OfflineYearResults";
import { isOfflineUser } from "../../../offline";
import { getTotalEmissionsForOffline } from "../../results/offline_results_table_in_form";
import OffsetPageOffline from "./other/offsetPageOffline";
import { getForms } from "../../../static/formRoutes";
import ShareModal from "../../results/comp/shareSocial";
// const workerInstance = worker();

const YearAnalysis = ({ graphOnly = false, scopeResultsOnly = false, metricsOnly = false, offsetPage = false, rough }) => {
  const [options, setOptions] = useState({
    wtt: "Included",
    type: "Location",
    reporting: "All",
    sites: getCurrentUserDesignation() !== designations.group_admin ? [findCompanies()[0]?.id] : findCompanies()?.map((c) => c.id),
    reportType: "Emissions",
  });

  const [dataSource, setDataSource] = useState(undefined);
  const [MetricsYear, setMetricsYear] = useState(String(getCurrentYear()));
  const [cols, setCols] = useState([]);
  const [scopeChart, setScopeChart] = useState([]);
  const [scopeYear, setScopeYear] = useState(getCurrentYear());
  let results = useSelector(selectResults);

  const navigate = useNavigate();

  const is_offline = getCurrentPlan() === "offline";

  // console.log(
  //   "analysis data",
  //   dataSource, cols
  // );

  // calculate result
  const setResults = (message) => {
    if (typeof message === "object" && message?.data?.dataSource) {
      console.log("data for pie chart", message.data, results);
      setDataSource(message.data.dataSource);
      setCols(message.data.moreInfo.cols);
      setScopeChart(message.data.moreInfo.scopechart);
    }
  };

  const company4Metrics = () => {
    //Sends back company if single company is selected.
    let company_ids = [];
    for (let entityId of options.sites) {
      company_ids.push(findSite(entityId)?.companyId || entityId);
    }
    company_ids = removeDuplicates(company_ids);
    console.log("store", store.getState().app);
    return company_ids.length === 1 ? company_ids[0] : false;
  };
  const createMetricsData = () => {
    const company = findCompany(company4Metrics());
    try {
      let metrics = company["metrics"][MetricsYear];
      let metricName = company["metrics"]["aMetricName"];
      const result = configDataSource(dataSource, cols)[3][MetricsYear];
      const obj = {
        "Emissions per employee": result / metrics["fte"],
        [`Emissions per million turnover (${metrics["curr"]})`]: result / metrics["to"],
      };
      if (metricName) {
        obj[`Emissions per ${metricName || "{not set}"}`] = result / metrics["aMetricNo"];
      }
      return beautifulObjectNumValues(obj);
    } catch (err) {
      return (
        <Typography.Text>
          Metrics aren't configured for the selected year.{" "}
          <Link to={isSustraxLite(["free", "basic"]) ? routePath.Profile : routePath.CompanyDetails(company?.id, "metrics&year=" + MetricsYear)}>
            Configure them now <RightOutlined />
          </Link>
        </Typography.Text>
      );
    }
  };
  useEffect(() => {
    setDataSource(undefined);
    if (results && getAnalysis) {
      const sites = findSitesInCompanies(options.sites);
      results = results.filter((result) => sites.includes(result.siteId));

      setResults({ data: getAnalysis(results, options, rough) });
      setTimeout(() => {
        getAllParamsFromLocation()?.scroll === "bottom" && scrollToElementWithId("offset-box");
      }, 500);
    }
  }, [results, options]);
  const [graphFilters, setGraphFilters] = useState({ col: String(getCurrentYear()) });

  const columns = createcols(cols, dataSource, options);
  console.log(scopeChart, "data for pie chart", graphFilters);
  const graphComponent = createGraphComponent(scopeChart, graphFilters, setGraphFilters, cols, "", { xLabel: "Years" });
  if (scopeResultsOnly)
    return (
      <InLineCards
        dataArray={configDataSource(dataSource, cols)?.map((x) => ({
          name: x["name"],
          result: x[scopeResultsOnly.year],
        }))}
      />
    );

  const handleManageMetrics = () => {
    let to = routePath.CompanyDetails(company4Metrics(), "metrics&year=" + MetricsYear);
    if (["free", "basic"].includes(getCurrentPlan())) {
      to = routePath.Profile + "?tab=metrics";
    }
    navigate(to);
  };
  const metricsbox = (
    <Boxed title={<div style={{ display: "flex" }}>Metrics</div>}>
      <div style={{ padding: 10 }}>
        {company4Metrics() ? (
          <>
            <div
              style={{
                display: " flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="metrics">
                <Typography.Title className="yearSelector-title" style={{ display: getCurrentPlan() === "free" ? "none" : "" }}>
                  Year{" "}
                  <span style={{ color: "green" }}>
                    <span style={{ fontSize: "0px" }} className="selector1">
                      <SimpleSelect
                        elemOnly
                        value={MetricsYear}
                        setValue={setMetricsYear}
                        options={cols.map((value) => ({
                          label: value,
                          value,
                        }))}
                      />
                    </span>
                  </span>
                </Typography.Title>
              </div>
              <div
                className="button-primary-1 manageMetrics-btn"
                style={{
                  padding: 5,
                  width: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={handleManageMetrics}
              >
                Manage Metrics
                <BulbOutlined />
              </div>
            </div>
            {createMetricsData()}
          </>
        ) : (
          <div>
            <WarningOutlined /> Please choose only one company.
          </div>
        )}
      </div>
    </Boxed>
  );
  if (graphOnly && !dataSource) return <SkeletonBarChart bars={1} />;
  if (graphOnly) return graphComponent;
  if (metricsOnly) return metricsbox;
  const optionsbox = <Options options={options} setOptions={setOptions} />;

  let offsetBox = GetScopeOffsetBox(
    configDataSource(dataSource, cols)?.map((x) => ({
      name: x["name"],
      result: x[scopeYear],
    })),
    navigate,
    offsetPage,
    cols,
    scopeYear,
    setScopeYear
  );

  if (is_offline)
    offsetBox = (
      <div>
        <OffsetPageOffline
          offsetPage={!offsetPage}
          data={getForms().map((form) => {
            const totalEmissions = getTotalEmissionsForOffline(filterResultsByName(form.name));
            return {
              title: form.title,
              value: totalEmissions,
            };
          })}
        />
      </div>
    );
  if (offsetPage) return offsetBox;

  if (is_offline) {
    const total = getTotalEmissionsForOffline();
    if (!total) return <Empty description="No calculations added, please add some inputs" />;
    return (
      <div className="page">
        <Heading type={is_offline ? "Results" : <T1 pos={"analysis.yearly.title"} />} />
        <Typography.Title level={3}>Your Emissions = {total} tCO₂e </Typography.Title>
        <ShareModal number={total} />
        {offsetBox}
        <Boxed title={"Results Breakdown"} className="analysis-result">
          <DisplayResultsTableData />
        </Boxed>
      </div>
    );
  }

  return (
    <div className="page">
      <Heading type={is_offline ? "Results" : <T1 pos={"analysis.yearly.title"} />} />
      {!isSustraxLite() && !is_offline ? optionsbox : null}
      <div className="analysis-graph">{graphComponent}</div>
      {is_offline ? null : (
        <Boxed title={"Results Breakdown"} className={"analysis-result"}>
          <ExportButton dataSource={dataSource} fileName={"Sustrax Carbon Footprint Export"} cols={cols} options={options} />
          <Table {...tableProps(dataSource, results, columns, cols)} />
        </Boxed>
      )}
      {is_offline ? "" : <div className="analysis-metrics">{metricsbox}</div>}
      {isSustraxLite() ? <div className="analysis-options">{optionsbox}</div> : null}
      {!store.getState().app.affiliate && <div className="analysis-offset">{offsetBox}</div>}
      <GPTRecommendations data={dataSource} cols={cols} options={options} />
    </div>
  );
};

export default YearAnalysis;
