import React from "react";
import { Card, Checkbox, Input, Button, Row, Col, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import { routePath } from "../../../../../static/routes.static";
import { ArrowRightOutlined } from "@ant-design/icons";

const fixDecimals = (n) => {
  try {
    return n.toFixed(2);
  } catch (err) {
    return n;
  }
};

const OffsetCalculatorOffline = ({ data, selectedValues, handleCheckboxChange, totalFromCheckboxes, customValue, setCustomValue, getOffsetText, offsetPage }) => (
  <Card
    title={
      !offsetPage ? (
        <div>
          Carbon Offsetting{" "}
          {/* <small>
            This is based on your
            <Link to={routePath.Analysis("Years")}> results</Link>
          </small> */}
        </div>
      ) : (
        <div>
          Carbon Offsetting{" "}
          <Link to={routePath.Offset}>
            More details <ArrowRightOutlined />
          </Link>
        </div>
      )
    }
    headStyle={{ fontSize: 20, borderBottom: "2px solid #f0f0f0" }}
    style={{ width: "100%" }}
  >
    <Row gutter={24}>
      <Col xs={24} md={14}>
        <div
          style={{
            borderRight: "1px solid #f0f0f0",
            height: "100%",
            paddingRight: 16,
          }}
        >
          {data.map((item) => (
            <div
              key={item.title}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              <Checkbox checked={selectedValues.some((val) => val.title === item.title)} onChange={() => handleCheckboxChange(item)} />
              <span style={{ marginLeft: 12, flex: 1, fontSize: "14px" }}>{item.title}</span>
              <span style={{ color: "#666", fontSize: "14px" }}>{item.value} tonnes CO₂e</span>
            </div>
          ))}

          {totalFromCheckboxes > 0 && (
            <div style={{ marginTop: 16 }}>
              <Button type="primary" block size="large" onClick={() => window.open(`https://www.carbonfootprint.com/offset.aspx?o=${totalFromCheckboxes}&r=SustraxVita`, "_blank")}>
                Offset {totalFromCheckboxes} Tonnes CO₂e
              </Button>
              {getOffsetText(totalFromCheckboxes)}
              <Typography.Text type="secondary">Carbon credits can only be allocated and retired in whole numbers</Typography.Text>
            </div>
          )}
        </div>
      </Col>

      <Col xs={24} md={10}>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center", marginBottom: 16 }}>
            <h2>OR</h2>
            <h4 style={{ margin: "0 0 16px 0" }}>Offset Custom Amount (tonnes CO₂e)</h4>
            <Input size="large" type="number" placeholder="Enter amount in tonnes CO₂e" onChange={(e) => setCustomValue(Number(e.target.value))} style={{ marginBottom: 8 }} />
            <Button
              size="large"
              type="primary"
              block
              onClick={() => window.open(`https://www.carbonfootprint.com/offset.aspx?o=${customValue}&r=SustraxVitaCustom`, "_blank")}
              disabled={!customValue || customValue <= 0}
            >
              Offset {customValue > 0 ? `${customValue} tonnes CO₂e` : "Custom Amount"}
            </Button>
            {customValue > 0 && getOffsetText(customValue)}
            <Typography.Text type="secondary">Carbon credits can only be allocated and retired in whole numbers</Typography.Text>
          </div>
        </div>
      </Col>
    </Row>
  </Card>
);

export default OffsetCalculatorOffline;
