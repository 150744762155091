import React, { useState } from "react";
import { Card, Carousel } from "antd";

export const randomlyPick5 = () => {
  const images = [
    "https://live.staticflickr.com/65535/53261815791_f335252deb.jpg",
    "https://live.staticflickr.com/65535/53132088838_ec39fd686b_z.jpg",
    "https://live.staticflickr.com/65535/54155949607_4e2e7c897a_z.jpg",
    "https://live.staticflickr.com/65535/53799592809_84de02c9ee_z.jpg",
    "https://live.staticflickr.com/65535/53775662138_35d9c00320_z.jpg",
    "https://live.staticflickr.com/65535/53941227997_d2fb588308_z.jpg",
    "https://live.staticflickr.com/65535/52476286667_94d7006ca9.jpg",
    "https://live.staticflickr.com/65535/52749286169_4899bccce1.jpg",
    "https://live.staticflickr.com/65535/54107355326_07182cc0ab.jpg",
    "https://live.staticflickr.com/65535/52477252255_8f8e663089.jpg",
    "https://live.staticflickr.com/65535/53971806202_05ec874941_z.jpg",
    "https://live.staticflickr.com/65535/53720673484_51e6388062_z.jpg",
    "https://live.staticflickr.com/65535/54107731045_028f219ec6.jpg",
    "https://live.staticflickr.com/65535/52477064074_e86a5803be_m.jpg",
    "https://live.staticflickr.com/65535/52477064054_e9ac5a80dd.jpg",
    "https://live.staticflickr.com/65535/52714991339_39c4be9769_b.jpg",
    "https://live.staticflickr.com/65535/52573713053_23ff98265d.jpg",
    "https://live.staticflickr.com/65535/52823941136_58dbc2a313_o.jpg",
    "https://live.staticflickr.com/65535/52477063824_1d8b0f1a75_m.jpg",
    "https://live.staticflickr.com/65535/53359773351_acd6d0b50c.jpg",
    "https://live.staticflickr.com/65535/52499585729_322b1e8695.jpg",
    "https://live.staticflickr.com/65535/52722000946_9277d610fe_b.jpg",
    "https://live.staticflickr.com/65535/53484786169_57ccaab7af_z.jpg",
    "https://live.staticflickr.com/65535/53070789285_2cefd9857b_z.jpg",
    "https://live.staticflickr.com/65535/53071055685_679ee4c41b_z.jpg",
    "https://live.staticflickr.com/65535/52477333023_c1460380c6.jpg",
    "https://live.staticflickr.com/65535/54132292749_a3a220a2c9.jpg",
    "https://live.staticflickr.com/65535/53894251667_811b30eb3c_z.jpg",
    "https://live.staticflickr.com/65535/52502274784_fe0e3273d8.jpg",
    "https://live.staticflickr.com/65535/52476789166_3d1556f00a.jpg",
    "https://live.staticflickr.com/65535/52476789226_2a45ed1386.jpg",
    "https://live.staticflickr.com/65535/53951409848_5c73fdaf9b_z.jpg",
    "https://live.staticflickr.com/65535/52476286782_ae2b1d7c8b.jpg",
    "https://live.staticflickr.com/65535/53130776162_ac6a4fe1e0_z.jpg",
    "https://live.staticflickr.com/65535/52476789116_d5529aeef3.jpg",
    "https://live.staticflickr.com/65535/52476287157_008d28f660_z.jpg",
    "https://live.staticflickr.com/65535/53775530208_2d8ab9566d_z.jpg",
    "https://live.staticflickr.com/65535/52477252225_f4d222e30a_w.jpg",
    "https://live.staticflickr.com/65535/52477332763_7f97baf65b.jpg",
    "https://live.staticflickr.com/65535/52477252810_8f8381d5d2.jpg",
    "https://live.staticflickr.com/65535/52477252770_f805dd1aff.jpg",
    "https://live.staticflickr.com/65535/52476287247_f3ed093f54.jpg",
    "https://live.staticflickr.com/65535/52477332893_1230453352.jpg",
    "https://live.staticflickr.com/65535/52477252415_10127e3b25_w.jpg",
    "https://live.staticflickr.com/65535/53069969857_6b427af93b_z.jpg",
    "https://live.staticflickr.com/65535/52476696886_0417932210.jpg",
    "https://live.staticflickr.com/65535/52477332513_c62edc4b68_m.jpg",
    "https://live.staticflickr.com/65535/54155068751_a8d54c13b2_z.jpg",
    "https://live.staticflickr.com/65535/52477063869_3615924f93.jpg",
    "https://live.staticflickr.com/65535/52476788901_ae3ff0023f_w.jpg",
    "https://live.staticflickr.com/65535/52476788951_9eaca1c95b.jpg",
    "https://live.staticflickr.com/65535/54155160166_cf22d21bf8_z.jpg",
    "https://live.staticflickr.com/65535/52477333043_00068822f2.jpg",
    "https://live.staticflickr.com/65535/52477332313_9fba7cf9d1_n.jpg",
    "https://live.staticflickr.com/65535/52476789131_806193d74f.jpg",
    "https://live.staticflickr.com/65535/52476286842_367e391a00.jpg",
    "https://live.staticflickr.com/65535/53329938485_48505ab337_z.jpg",
    "https://live.staticflickr.com/65535/52476789526_3213caa87e.jpg",
    "https://live.staticflickr.com/65535/52477253005_91c43532dc.jpg",
    "https://live.staticflickr.com/65535/52476286937_48c8977ebb.jpg",
    "https://live.staticflickr.com/65535/52476630756_276f776d5b.jpg",
    "https://live.staticflickr.com/65535/52477332428_7f6b7bb61c.jpg",
    "https://live.staticflickr.com/65535/52477252665_52f5142f6b.jpg",
    "https://live.staticflickr.com/65535/52477252525_0b08ea76b9.jpg",
    "https://live.staticflickr.com/65535/52477063924_70fed70890_n.jpg",
    "https://live.staticflickr.com/65535/53444721200_029f1b390b_z.jpg",
    "https://live.staticflickr.com/65535/52476287197_06d835d9b3.jpg",
    "https://live.staticflickr.com/65535/52476789296_5a251db53a_n.jpg",
    "https://live.staticflickr.com/65535/52476789196_a343001848.jpg",
    "https://live.staticflickr.com/65535/52502360104_76ec59c5f1.jpg",
    "https://live.staticflickr.com/65535/52476789506_88acb0a8f7.jpg",
    "https://live.staticflickr.com/65535/52476789496_5705054756.jpg",
    "https://live.staticflickr.com/65535/52476789536_a33466fd36.jpg",
    "https://live.staticflickr.com/65535/52476286917_63a4bc36e8.jpg",
    "https://live.staticflickr.com/65535/52483666550_b38c6c0f7a.jpg",
    "https://live.staticflickr.com/65535/52477332803_ce58b9d424.jpg",
    "https://live.staticflickr.com/65535/52477252240_66fbbf867a.jpg",
    "https://live.staticflickr.com/65535/52477252845_902b995450.jpg",
    "https://live.staticflickr.com/65535/52477332573_4c0f379dd4.jpg",
    "https://live.staticflickr.com/65535/52477063959_01062f8de1.jpg",
    "https://live.staticflickr.com/65535/52477064094_f4da33604c_m.jpg",
    "https://live.staticflickr.com/65535/52477252960_c9bf9a805a.jpg",
    "https://live.staticflickr.com/65535/52477252950_8a3761bf83.jpg",
    "https://live.staticflickr.com/65535/52504846882_4dfa20be40.jpg",
    "https://live.staticflickr.com/65535/52714157127_c5a3498503_b.jpg",
    "https://live.staticflickr.com/65535/53071216343_f42f1e2cbf_w.jpg",
    "https://live.staticflickr.com/65535/53070989100_8f76bd939b_z.jpg",
    "https://live.staticflickr.com/65535/53070918770_b67e414281_z.jpg",
    "https://live.staticflickr.com/65535/53215340865_5cd3d87914_z.jpg",
    "https://live.staticflickr.com/65535/53334203154_73bd179694.jpg",
    "https://live.staticflickr.com/65535/53837182250_9eddeaefc6_z.jpg",
    "https://live.staticflickr.com/65535/53866965073_6b6e1e7ca0_z.jpg",
    "https://live.staticflickr.com/65535/53908926812_3de66ea3ce_z.jpg",
    "https://live.staticflickr.com/65535/54155987777_25d2ac0706_z.jpg",
    "https://live.staticflickr.com/65535/54155862475_f4e4a70ed6_z.jpg",
    "https://live.staticflickr.com/65535/52476789326_a24a478fff_w.jpg",
    "https://live.staticflickr.com/65535/52477240838_dcac01ed93.jpg",
    "https://live.staticflickr.com/65535/52477063919_52a7b84010_w.jpg",
    "https://live.staticflickr.com/65535/52476789641_67b5a55e14.jpg",
    "https://live.staticflickr.com/65535/52476789031_cbd5ce2a08_n.jpg",
    "https://live.staticflickr.com/65535/52476789436_b676fc8d9c_n.jpg",
    "https://live.staticflickr.com/65535/52477063874_597a4295ec_m.jpg",
    "https://live.staticflickr.com/65535/52477064169_7e8e4b0e3d_m.jpg",
    "https://live.staticflickr.com/65535/52477332668_5aecf86d34.jpg",
    "https://live.staticflickr.com/65535/52477252315_f96a60fe17.jpg",
    "https://live.staticflickr.com/65535/52477332493_e9d1315f46.jpg",
    "https://live.staticflickr.com/65535/52476788881_0429791e09.jpg",
    "https://live.staticflickr.com/65535/52477332698_3b66a9f368.jpg",
    "https://live.staticflickr.com/65535/52749286169_c5e10513dc_o.jpg",
  ];
  return images.sort(() => Math.random() - 0.5).slice(0, 5);
};

const ImagesSection = () => {
  const [images] = useState(randomlyPick5());

  return (
    <Card
      title="Our Impact Projects"
      headStyle={{ fontSize: 20, borderBottom: "2px solid #f0f0f0" }}
      style={{ width: "100%" }}
    >
      <Carousel autoplay autoplaySpeed={3000} effect="fade" dots={true}>
        {images.map((image, index) => (
          <div key={index}>
            <div
              style={{
                position: "relative",
                paddingTop: "66.67%", // 3:2 aspect ratio
                borderRadius: 8,
                overflow: "hidden",
              }}
            >
              <img
                src={image}
                alt={`Carbon Offset Project ${index + 1}`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          </div>
        ))}
      </Carousel>
    </Card>
  );
};

export default ImagesSection;
