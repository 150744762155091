import React from 'react';
import { FaWhatsapp, FaFacebook, FaTwitter, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { TbBrandBluesky } from "react-icons/tb";

const ShareModal = ({ number }) => {
  const shareUrl = 'https://www.carbonfootprint.com/sustraxvita.html';
  const message = `I've just calculated my carbon emissions using Sustrax Vita. I have produced ${number} tCO₂e this year. I pledge to reduce my carbon emissions. #SustraxVita`;
  const shareLinks = [
    {
      name: 'Facebook',
      icon: <FaFacebook size={18} style={{ color: '#1877F2' }} />, 
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`,
    },
    {
      name: 'X',
      icon: <FaXTwitter size={18} style={{ color: '#1DA1F2' }} />,
      url: `https://x.com/intent/tweet?text=${encodeURIComponent(`${message} ${shareUrl}`)}`,
    },
    {
      name: 'WhatsApp',
      icon: <FaWhatsapp size={18} style={{ color: '#25D366' }} />,
      url: `https://wa.me/?text=${encodeURIComponent(`${message} ${shareUrl}`)}`,
    },
    {
      name: 'LinkedIn',
      icon: <FaLinkedin size={18} style={{ color: '#0A66C2' }} />,
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent('My Carbon Emissions Pledge')}&summary=${encodeURIComponent(message)}`,
    },
    {
      name: 'Bluesky',
      icon: <TbBrandBluesky size={18} style={{ color: '#3333FF' }} />,
      url: `https://bsky.app/post?text=${encodeURIComponent(`${message} ${shareUrl}`)}`,
    },
    {
      name: 'Email',
      icon: <FaEnvelope size={18} style={{ color: '#D44638' }} />,
      url: `mailto:?subject=${encodeURIComponent('My Carbon Emissions Pledge')}&body=${encodeURIComponent(`${message} ${shareUrl}`)}`,
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'flex-start', marginBottom: '20px' }}>
      <p style={{ fontWeight: '600', color: '#555', fontSize: '18px', marginBottom: '8px' }}>
        Share your emissions and make a <span style={{ color: 'green' }}>carbon reduction pledge</span>
      </p>
      <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
        {shareLinks.map((platform, index) => (
          <a
            key={index}
            href={platform.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              transition: 'transform 0.2s ease',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          >
            {platform.icon}
            <span style={{ fontWeight: '500', color: '#333', fontSize: '14px', marginLeft: '6px' }}>{platform.name}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default ShareModal;
