import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BugOutlined, BulbOutlined, DownOutlined, MenuOutlined, PullRequestOutlined, QuestionOutlined, SettingOutlined, TranslationOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import { FaAccessibleIcon, FaChalkboardTeacher, FaCross, FaLightbulb } from "react-icons/fa";
import { IoAccessibility } from "react-icons/io5";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import "./styles.css";
import { SiBinance, SiChatbot } from "react-icons/si";
import { designations, getCurrentUserDesignation } from "../../helper/attribute";
import DisplaySettings from "../../pages/super-admin/Components/DisplaySettings";
import { selectTheme, setTheme } from "../../redux/features/appSlice";
import ReportBug from "../UserHelp/ReportBug";
import TranslateSite from "../UserHelp/Translate";
import UserTour from "../tour";
import { selectUserDoc } from "../../redux/features/userSlice";
import { getCurrentPlan, isSustrax, isSustraxAnnual, isSustraxLite } from "../../helper/plans";
import useSize from "../../hooks/useSize";
import { useNavigate } from "react-router-dom";
import { routePath } from "../../static/routes.static";
import SustraxBot from "../../pages/chatbot";
import RequestFeature from "../UserHelp/RequestFeature";
import Access from "./Access";
import { isOfflineUser } from "../../offline";

const FloatingButtons = ({ refetchData }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserDoc);
  const { isSmall, isExtraSmall } = useSize();
  const { isDarkMode } = useSelector(selectTheme);
  const navigate = useNavigate();
  const { isMobile } = useSize();

  const [display, setDisplay] = useState({
    superAdminModal: false,
    reportBug: false,
    translate: false,
    tutorial: false,
    ai_bot: false,
    access: false,
  });

  const toggleModal = (key) => {
    setDisplay((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  useEffect(() => {
    if (user && getCurrentPlan() === "free" && !user.tut_shown_on && !user.tut_completed_on) {
      toggleModal("tutorial");
    }
  }, [user]);

  const handleAIChatClick = () => {
    if (isMobile) {
      navigate(routePath.chat);
      if (display.ai_bot) {
        //close desktop bot
        setDisplay({});
      }
    } else {
      setDisplay({ ...display, ai_bot: !display.ai_bot });
    }
  };

  return (
    <div>
      {isSustrax(["demo", "pro"]) && (
        <FloatButton
          tooltip={`${!display.ai_bot ? "Open" : "Close"} Sustrax MX Assistant`}
          shape="square"
          className={!display.ai_bot ? "floating-chat-icon" : ""}
          style={{ right: 10, bottom: 60 }}
          onClick={handleAIChatClick}
          icon={
            display.ai_bot ? (
              <>
                <DownOutlined />
              </>
            ) : (
              <SiChatbot color="lightgreen" />
            )
          }
        ></FloatButton>
      )}
      <FloatButton.Group
        shape="square"
        trigger="click"
        className="floating-buttons"
        tooltip="Click to open menu."
        icon={<MenuOutlined />}
        style={{
          right: 10,
          bottom: 10,
          // display: display.ai_bot ? "none" : "initial",
        }}
      >
        {getCurrentUserDesignation() === designations.super_admin && <FloatButton onClick={() => toggleModal("superAdminModal")} icon={<SettingOutlined />} />}
        <FloatButton
          tooltip="Toggle Dark/Light Mode"
          icon={<DarkModeSwitch style={{ transform: "translateX(-2px)" }} checked={isDarkMode} onClick={() => dispatch(setTheme({ isDarkMode: !isDarkMode }))} />}
        />
        {isOfflineUser() ? (
          <></>
        ) : (
          <>
            <FloatButton icon={<BugOutlined />} tooltip="Report a bug" onClick={() => toggleModal("reportBug")} />
            <FloatButton icon={<BulbOutlined />} tooltip="Request Feature" onClick={() => toggleModal("requestFeature")} />
          </>
        )}
        <FloatButton icon={<TranslationOutlined />} tooltip="Translate" onClick={() => toggleModal("translate")} />
        {!isSmall && !isExtraSmall && isSustraxLite() && <FloatButton icon={<FaChalkboardTeacher />} tooltip="Tutorial" onClick={() => toggleModal("tutorial")} />}
        <FloatButton icon={<IoAccessibility />} tooltip="Accessibility" onClick={() => toggleModal("access")} />
        <FloatButton.BackTop tooltip="Scroll to top" />
      </FloatButton.Group>

      <ReportBug modalVisible={display.reportBug} setModalVisible={(visible) => setDisplay((prev) => ({ ...prev, reportBug: visible }))} />
      <RequestFeature modalVisible={display.requestFeature} setModalVisible={(visible) => setDisplay((prev) => ({ ...prev, requestFeature: visible }))} />
      <TranslateSite visible={display.translate} setVisible={(visible) => setDisplay((prev) => ({ ...prev, translate: visible }))} />
      <DisplaySettings refetchData={refetchData} modalVisible={display.superAdminModal} setModalVisible={(visible) => setDisplay((prev) => ({ ...prev, superAdminModal: visible }))} />

      <Access visible={display.access} setVisible={(visible) => setDisplay((prev) => ({ ...prev, access: visible }))} />
      <UserTour open={display.tutorial} setOpen={(visible) => setDisplay((prev) => ({ ...prev, tutorial: visible }))} />
      {
        <div style={{ display: display.ai_bot ? "inherit" : "none" }}>
          <SustraxBot fullPage={false} />
        </div>
      }
    </div>
  );
};

export default FloatingButtons;
