import { CloudUploadOutlined, RightOutlined } from "@ant-design/icons";
import { Alert, Button, Collapse, Divider, Space, Typography } from "antd";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { T1 } from "../../appconfig/texts";
import DataLinkInput from "../../components/input/DataLinkInput";
import CommentInput from "../../components/input/comment";
import FileInput from "../../components/input/datafile";
import NumberInput from "../../components/input/number";
import FakeLabel from "../../components/input/select";
import SelectSites from "../../components/select/site";
import SelectTags from "../../components/select/tags";
import { getAppInfo } from "../../firebase/appinfo/create";
import { updateCompany } from "../../firebase/company/update";
import { catcher } from "../../firebase/util";
import { getCurrentPlan, planValue } from "../../helper/plans";
import { vspace } from "../../helper/uimaker";
import { findForm } from "../../static/formRoutes";
import { routePath } from "../../static/routes.static";
import MetricsInput from "./comp/metricsInput";
import { FaChalkboardTeacher } from "react-icons/fa";
import FormContext from "../../contexts/FormContext";
import SelectSearch from "../../components/select/SearchSelect";
import DataFileMultiple from "../../components/input/dataFileMultiple";
import { findCompany, findFormInstructions, findSite } from "../../helper/attribute";
import InputAI from "./ai/Input-AI";
import ErrorBoundary from "../../ErrorBoundary";
import { isOfflineUser } from "../../offline";
import { getOfflineFormInstructions } from "./offline/instructions";

const { Panel } = Collapse;

const StyledMain = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledForm = styled.div`
  .form {
    width: 60%;
    @media (max-width: 600px) {
      width: 98%;
    }
    .fakelabel {
      + .fakelabel {
        border-radius: 0;
      }
    }
  }
  .ins {
    background: "grey";
    width: 40%;
    padding: 0px 10px;
    @media (max-width: 600px) {
      width: 100%;
      padding: 0px 0px;
    }
  }
  .ins pre {
    white-space: pre-wrap;
  }
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export default function InputDataPage({ data, setData, editId, isBulkPage, bulkPageErr }) {
  const isMobile = useMediaQuery({ query: "(max-width: 599px)" });
  const site = useMemo(() => findSite(data.siteId), [data.siteId]);
  const plan = useMemo(() => getCurrentPlan(), []);
  const company = useMemo(() => findCompany(site?.companyId), [site?.companyId]);
  const form = useMemo(() => findForm(data.name), [data.name]);
  const navigate = useNavigate();
  const [moreData, setMoreData] = useState(false);
  const [vidLoading, setVidLoading] = useState(false);

  useEffect(() => {
    // Optionally preload form instructions or other data if necessary
  }, []);

  const handleDisplayFormVideo = useCallback(
    async (formName) => {
      formName = `${formName}-${getCurrentPlan()}`;
      catcher(
        async () => {
          const links = await getAppInfo("form_vid_links");
          if (links?.[formName]) {
            setVidLoading(false);
            navigate(routePath.learn(formName));
          } else {
            throw new Error("Video not found!");
          }
        },
        { setLoading: setVidLoading, success_msg: "" }
      );
    },
    [navigate]
  );

  const handleBulkUploadNavigate = useCallback(() => {
    navigate(routePath.BulkUpload(data.name));
  }, [navigate, data.name]);

  const handleAddTag = useCallback(
    async (tagName) => {
      const updatedTags = company?.resulttags ? [...company.resulttags, tagName] : [tagName];
      await updateCompany(company?.id, { resulttags: updatedTags });
    },
    [company?.resulttags, company?.id]
  );

  if (!form) return `FORM NOT FOUND in ${JSON.stringify(data)}`;

  const resultTags = company?.resulttags;
  const inputMetrics = company?.inputMetrics;
  const siteCurrency = site?.curr || "GBP";

  const comment = <CommentInput value={data.comment} setValue={(comment) => setData({ ...data, comment })} />;
  return (
    <StyledMain>
      {bulkPageErr && (
        <div style={{ padding: "10px 0px" }}>
          <Alert showIcon type="warning" message={bulkPageErr} />
        </div>
      )}
      {!isBulkPage && !editId && (
        <div className="header">
          <Typography.Title level={4} style={{ display: "flex", gap: 10, alignItems: "center" }}>
            <img width={"30px"} src={form?.bg} alt={form?.title} /> {form.title}
          </Typography.Title>
          <Space
            style={{
              display: isOfflineUser() ? "none" : "flex",
              flexDirection: isMobile ? "column" : "",
            }}
          >
            <Button
              type="ghost"
              style={{
                margin: isMobile ? "" : "10px",
                cursor: "pointer",
                display: "flex",
                gap: 3,
                alignItems: "center",
              }}
              onClick={() => handleDisplayFormVideo(form.name)}
              loading={vidLoading}
              className="input-tutorial"
            >
              <FaChalkboardTeacher style={{ fontSize: "1.1rem" }} />
              Tutorial
            </Button>
            {planValue("canBulkUploadForms") ? (
              <Button type="primary" onClick={handleBulkUploadNavigate}>
                <CloudUploadOutlined /> <T1 pos="form.bulkButton" />
              </Button>
            ) : (
              ""
            )}
          </Space>
        </div>
      )}
      <Divider />
      {/* <InputAI data={data} setData={setData}/> */}
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "flex-end",
        }}
      >
        <div style={{ width: isMobile ? "100%" : "61%" }}>
          <Typography.Title style={{ margin: isMobile ? 0 : "" }} level={5}>
            <T1 pos="form.ins" />
          </Typography.Title>
          <pre style={{ whiteSpace: "pre-wrap", color: "#5A5A5A", fontSize: 12 }}>{isOfflineUser() ? getOfflineFormInstructions(form.name) : findFormInstructions(form.name)}</pre>
        </div>
        {isMobile || isOfflineUser() ? null : (
          <div style={{ width: isMobile ? "100%" : "40%" }}>
            <Typography.Title level={5}>
              <T1 pos="form.more" />
            </Typography.Title>
          </div>
        )}
      </div>
      <StyledForm>
        <div className="form">
          {!isBulkPage && plan !== "free" && (
            <SelectSites
              value={data.siteId}
              handleSelect={(siteId) =>
                setData({
                  ...data,
                  siteId,
                  resulttag: null,
                  m_1: null,
                  m_2: null,
                  m_3: null,
                })
              }
            />
          )}

          {form?.component && <form.component data={data} setData={(newdata) => setData(newdata)} />}
        </div>
        <div
          className="ins"
          style={{
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          {isOfflineUser() ? <>{comment}</> : ""}
          <div style={{ display: isOfflineUser() ? "none" : "" }}>
            {isMobile && (
              <div style={{ width: isMobile ? "100%" : "40%" }}>
                <Typography.Title level={5}>
                  {/* <T1 pos="form.more" /> */}
                  Additional Details
                </Typography.Title>
              </div>
            )}

            <>
              <DataLinkInput title={<T1 pos="form.link" />} value={data.dataLink} setValue={(dataLink) => setData({ ...data, dataLink })} />
            </>
            {plan !== "free" ? (
              <div>
                <SelectSearch allowClear title="Data Tag" value={data?.resulttag} setValue={(resulttag) => setData({ ...data, resulttag })} options={resultTags} />
                <NumberInput value={data.cost} setValue={(cost) => setData({ ...data, cost })} title="Cost" addonAfter={siteCurrency} />
              </div>
            ) : (
              ""
            )}
            {comment}

            {planValue("canAddFileToForm") ? (
              <>
                <Typography.Title level={5}>Files</Typography.Title>
                <ErrorBoundary>
                  <DataFileMultiple files={data.file ? (Array.isArray(data.file) ? data.file : [data.file]) : []} setFiles={(files) => setData({ ...data, file: files })} maxFiles={5} />
                </ErrorBoundary>
              </>
            ) : (
              ""
            )}

            {/* <Collapse ghost defaultActiveKey={"3"}>
              <Panel header={<strong>Metrics</strong>} key="3"> */}
            {plan !== "free" ? <MetricsInput metrics={inputMetrics} data={data} setData={setData} /> : ""}
            {/* </Panel>
            </Collapse> */}
            {/* </FormContext.Provider> */}
          </div>
        </div>
      </StyledForm>
    </StyledMain>
  );
}
