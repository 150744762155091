import React, { useState } from "react";
import { Card, Carousel } from "antd";
import {
  CheckCircleFilled,
  RocketFilled,
  GlobalOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { randomlyPick5 } from "./ImagesSection";

const WhyOffsetWithUs = () => {
  const [images] = useState(randomlyPick5());

  return (
    <Card
      style={{
        width: "100%",
        padding: "32px",
        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        backgroundColor: "#ffffff", // Minimal white background
      }}
    >
      <div style={{ position: "relative" }}>
        <div
          style={{
            float: "right",
            width: "45%",
            marginLeft: "24px",
            marginBottom: "24px",
            borderRadius: "16px",
            overflow: "hidden",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
          }}
        >
          <Carousel autoplay autoplaySpeed={3000} effect="fade" dots={true}>
            {images.map((image, index) => (
              <div key={index}>
                <div
                  style={{
                    position: "relative",
                    paddingTop: "66.67%",
                    borderRadius: "16px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={image}
                    alt={`Carbon Offset Project ${index + 1}`}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </div>

        <h1
          style={{
            fontSize: "36px",
            color: "#2f855a", // Forest green
            marginBottom: "28px",
            borderBottom: "3px solid #2f855a", // Forest green border
            paddingBottom: "16px",
            fontWeight: "600",
          }}
        >
          Why Offset With Us
        </h1>

        <h2
          style={{
            fontSize: "26px",
            marginBottom: "24px",
            color: "forestgreen", // Forest green
            display: "flex",
            alignItems: "center",
            gap: "14px",
          }}
        >
          Take Action Against Climate Change
        </h2>

        <p
          style={{
            color: "#4a5568",
            marginBottom: "20px",
            lineHeight: "1.8",
            fontSize: "16px",
          }}
        >
          As an individual, you have the power to make a significant impact on
          the environment. It goes without saying you should try and reduce your
          carbon footprint by as much as you can.
        </p>
        <p
          style={{
            color: "#4a5568",
            marginBottom: "28px",
            lineHeight: "1.8",
            fontSize: "16px",
          }}
        >
          In addition, you can provide funding (via carbon offsetting) to
          support carbon reduction and removal projects around the world.
        </p>

        <h3
          style={{
            fontSize: "22px",
            marginBottom: "24px",
            color: "#2f855a", // Forest green
            clear: "both",
            display: "flex",
            alignItems: "center",
            gap: "14px",
          }}
        >
          <GlobalOutlined style={{ color: "#2f855a" }} />
          Why Offset Your Carbon Emissions?
        </h3>

        <ul
          style={{
            listStyle: "none",
            paddingLeft: "0",
            color: "#4a5568",
            marginBottom: "28px",
          }}
        >
          <li
            style={{
              marginBottom: "16px",
              display: "flex",
              alignItems: "flex-start",
              gap: "14px",
            }}
          >
            <div>
              <strong>Immediate Impact:</strong> Carbon offsetting funds
              projects that reduce emissions now, often faster than individual
              actions alone.
            </div>
          </li>
          <li
            style={{
              marginBottom: "16px",
              display: "flex",
              alignItems: "flex-start",
              gap: "14px",
            }}
          >
            <div>
              <strong>Global and Local Benefits:</strong> Offset projects not
              only help combat climate change but often also provide employment,
              health improvements, reforestation, and social benefits to
              communities in need around the world.
            </div>
          </li>
          <li
            style={{
              marginBottom: "16px",
              display: "flex",
              alignItems: "flex-start",
              gap: "14px",
            }}
          >
            <div>
              <strong>Compensation for Emissions:</strong> Offsetting provides
              you with a way to balance out the emissions you have already
              produced in your daily life, such as driving, flying, and heating
              your home.
            </div>
          </li>
        </ul>

        <h3
          style={{
            fontSize: "22px",
            marginBottom: "24px",
            color: "#2f855a", // Forest green
            display: "flex",
            alignItems: "center",
            gap: "14px",
          }}
        >
          <SafetyCertificateOutlined style={{ color: "#2f855a" }} />
          Benefits of Offsetting with Us
        </h3>

        <ul
          style={{
            listStyle: "none",
            paddingLeft: "0",
            color: "#4a5568",
          }}
        >
          <li
            style={{
              marginBottom: "16px",
              display: "flex",
              alignItems: "flex-start",
              gap: "14px",
            }}
          >
            <CheckCircleFilled
              style={{ color: "#38a169", marginTop: "4px", fontSize: "18px" }} // Green icon
            />
            <div>
              <strong>High-Quality Projects:</strong> We offer only the highest
              quality carbon offset projects that meet leading international
              verification standards.
            </div>
          </li>
          <li
            style={{
              marginBottom: "16px",
              display: "flex",
              alignItems: "flex-start",
              gap: "14px",
            }}
          >
            <CheckCircleFilled
              style={{ color: "#38a169", marginTop: "4px", fontSize: "18px" }} // Green icon
            />
            <div>
              <strong>Support for Innovation:</strong> For each tonne of CO2 you
              offset, we contribute 5% to the Freedom Flight Prize, accelerating
              the pathway to zero-carbon aviation technology.
            </div>
          </li>
          <li
            style={{
              marginBottom: "16px",
              display: "flex",
              alignItems: "flex-start",
              gap: "14px",
            }}
          >
            <CheckCircleFilled
              style={{ color: "#38a169", marginTop: "4px", fontSize: "18px" }} // Green icon
            />
            <div>
              <strong>Community and Biodiversity:</strong> For every 10 tonnes
              of CO2 offset, we plant an additional tree in Kenya, supporting
              local communities and biodiversity.
            </div>
          </li>
        </ul>
      </div>
    </Card>
  );
};

export default WhyOffsetWithUs;
