import { Layout, theme } from "antd";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/header";
import Sidebar from "../components/Siderbar";
import "./styles.css";
import useSize from "../hooks/useSize";
import { isOfflineUser } from "../offline";
const Private = () => {
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();
  const [visible, setVisible] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  useEffect(() => {
    const handleOutsideClick = (e) => {
      const sidebar = document.querySelector(".ant-layout-sider");
      if (visible && sidebar && !sidebar.contains(e.target)) {
        setVisible(false);
      }
    };

    // const onResize = () => {
    //   setVisible(window.innerWidth <= 768);
    // };

    document.addEventListener("mousedown", handleOutsideClick);
    // window.addEventListener("resize", onResize);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      // window.removeEventListener("resize", onResize);
    };
  }, [visible]);
  // if (window.location.href.includes("/offset")) {
  //   if (isOfflineUser())
  //     return (
  //       <div>
  //         <Outlet></Outlet>
  //       </div>
  //     );
  // }
  return (
    <Layout
      style={{
        backgroundColor: colorBgContainer,
        minHeight: "100vh",
      }}
    >
      <Sidebar
        visible={visible}
        colorBgContainer={colorBgContainer}
        setVisible={setVisible}
      />
      <Layout
        className="site-layout"
        style={visible ? { marginLeft: "0px" } : {}}
      >
        <Header
          setVisible={setVisible}
          visible={visible}
          colorBgContainer={colorBgContainer}
        />
        <div
          id="sustraxmx_content"
          style={{
            padding: isExtraSmall
              ? "10px 10px"
              : isSmall
              ? "10px 15px"
              : "20px 30px",
          }}
        >
          <Outlet />
        </div>
      </Layout>
    </Layout>
  );
};
export default Private;
