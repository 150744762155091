import React from "react";
import { useSelector } from "react-redux";
import { selectResults } from "../../redux/features/appSlice";
import { Table, Button, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { deleteResult } from "../../firebase/data/delete";
import moment from "moment";
import { fetchResults } from "../../firebase/data/get";
import { timeFormat } from "../../helper/time";
import { findForm } from "../../static/formRoutes";
import { getDetails } from "./CombinedTable";
import { tco2e } from "../../static/texts.static";
import useSize from "../../hooks/useSize";
import store from "../../redux/store";

export const getEmissionsForOffline = (record) => {
  let sum = (record.result ?? 0) + (record.wtt ?? 0);
  if (record.name === "electricity") {
    if (!record.osf) {
      // supplier factors overriten
      sum = (record.marketbased_result ?? 0) + (record.marketbased_wtt ?? 0) + (record.marketbased_tnd ?? 0) + (record.marketbased_wtt_tnd ?? 0);
    } else {
      sum += (record.tnd ?? 0) + (record.wtt_tnd ?? 0);
    }
  }
  return sum.toFixed(2);
};

export const getTotalEmissionsForOffline = (records) => {
  if (!records) records = store.getState().app.results;
  const n = records.reduce((acc, record) => acc + Number(getEmissionsForOffline(record)), 0);
  return n.toFixed(2);
};

const OfflineResultsTableInForm = ({ name, setKey }) => {
  const results = useSelector(selectResults);
  const { isMobile } = useSize();

  const filteredResults = results?.filter((result) => result.name === name) || [];

  const columns = [
    {
      title: "Date Added",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => date,
      hideOnMobile: true,
    },
    {
      title: <div>Emissions ({tco2e})</div>,
      key: "emissions",
      render: (_, record) => <span style={{ fontWeight: "bold" }}>{getEmissionsForOffline(record)}</span>,
    },
    {
      title: "Details",
      render: (record) => {
        return getDetails(record.name, record).report;
      },
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      render: (text) => (
        <Tooltip title={text}>
          <span
            style={{
              color: "#888",
              maxWidth: "200px", // adjust this value as needed
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "block",
            }}
          >
            {text}
          </span>
        </Tooltip>
      ),
      hideOnMobile: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Popconfirm
          title="Are you sure you want to delete this result?"
          onConfirm={() => {
            deleteResult(record.id);
            fetchResults(name);
            setTimeout(() => {
              setKey((key) => key + 199 + "x");
            }, 100);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button type="text" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ].filter((x) => {
    if (isMobile) {
      return !x.hideOnMobile;
    }
    return true;
  });

  // if (!filteredResults?.length) return null;

  return (
    <Table
      scroll={{ x: 400, y: 1000 }}
      style={{ marginTop: 10 }}
      columns={columns}
      locale={{ emptyText: "No entries, please add some data above." }}
      dataSource={filteredResults}
      rowKey="id"
      size="small"
      pagination={{ pageSize: 5 }}
      //title={() => <h2>{findForm(name)?.title}</h2>}
    />
  );
};

export default OfflineResultsTableInForm;
