import React from "react";
import { Collapse } from "antd";

const { Panel } = Collapse;

const FAQ = () => (
  <div style={{ marginBottom: "32px", padding: "0 16px" }}>
    <h3 style={{ fontSize: "20px", marginBottom: "16px", color: "#2c5282" }}>
      Frequently Asked Questions
    </h3>
    <Collapse accordion>
      <Panel header="What is a Carbon Offset?" key="1">
        <p style={{ color: "#4a5568", lineHeight: "1.6" }}>
          A carbon offset compensates for your emissions by funding an
          equivalent carbon dioxide saving elsewhere.
        </p>
      </Panel>
      <Panel header="Is Offsetting the Solution to Climate Change?" key="2">
        <p style={{ color: "#4a5568", lineHeight: "1.6" }}>
          Offsetting is a vital tool in the fight against climate change, but it
          must be combined with efforts to reduce emissions as much as possible.
        </p>
      </Panel>
      <Panel header="Are Our Offsets Verified?" key="3">
        <p style={{ color: "#4a5568", lineHeight: "1.6" }}>
          Yes, our offsets are verified against the leading international
          standards such as The Gold Standard and Verra's Verified Carbon
          Standard (VCS).
        </p>
      </Panel>
    </Collapse>
  </div>
);

export default FAQ;
