import { Divider, Empty, Modal, Spin, theme, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Select1 from "../select/select1";
import "./style.css";
import { findSite, firstCompany } from "../../helper/attribute";
import { getNextTwelveMonths, timeFormat } from "../../helper/time";
import { extractYears, getResultDates } from "../../pages/results/resultsCalcs";
import DownloadDomImage from "../canvas/downloadDomImage";
import { getFormName } from "../../pages/extras/testing/config";
import { graph_colors } from "../../pages/extras/testing/charts/config";
import moment from "moment";
import { cleanArray, fixDecimals, removeDuplicates, removeDuplicatesFast, removePropsFromArray } from "../../helper/wodash";
import { useSelector } from "react-redux";
import { selectTheme } from "../../redux/features/appSlice";
import useSize from "../../hooks/useSize";
import { filterFactors } from "../../helper/emissionfactors";
let currentYearCacher = undefined;

let itemColorMapping = {};

function fillMissingMonths(data) {
  const existingMonths = new Set(data.map((entry) => entry.name));
  const newData = [];

  data.forEach((entry) => {
    const { name } = entry;
    const date = moment(name, "MMM YYYY");
    const year = date.year();

    for (let month = 0; month < 12; month++) {
      const fullName = moment().year(year).month(month).format("MMM YYYY");

      if (!existingMonths.has(fullName)) {
        newData.push({ name: fullName, result: "0" });
        existingMonths.add(fullName);
      }
    }
  });

  return [...data, ...newData].sort((a, b) => moment(a.name, "MMM YYYY").valueOf() - moment(b.name, "MMM YYYY").valueOf());
}

export default function BarReChart({ form, results, heading, fullDataRows = [], segregate_by = false, defaultFilters }) {
  let YAXIS_OPTIONS = ["tCO₂e", "kWh", "Count"];
  let XAXIS_OPTIONS = ["Months", "Fiscal Year", "Calendar Year"];
  const [loading, setLoading] = useState(true);
  const { isMobile } = useSize();

  // OPTIONS FILTERS

  // YAXIS_OPTIONS

  if (Array.isArray(results) && results[0] && results[0].hasOwnProperty("kwh")) {
  } else {
    YAXIS_OPTIONS = YAXIS_OPTIONS.filter((option) => option !== "kWh");
    console.log(results, YAXIS_OPTIONS);
  }

  // XAXIS_OPTIONS

  if (firstCompany()?.reporting_year_starting_month === "January") {
    XAXIS_OPTIONS = XAXIS_OPTIONS.filter((x) => x !== "Calendar Year");
  }

  const [segregator, setSegrigator] = useState("none");
  const [yaxis, setYaxis] = useState("tCO₂e");
  const [xaxis, setXaxis] = useState(XAXIS_OPTIONS[0]);
  const [larged, setLarged] = useState(false);
  const [currentYear, setCurrentYear] = useState(0); // Index of the currently visible year
  const [uniqueYears, setUniqueYears] = useState([]); // Array to store unique years
  const [type, setType] = useState("Location");
  const [wtt, setWtt] = useState("Included");
  const { isDarkMode } = useSelector(selectTheme);

  console.log(segregator);
  useEffect(() => {
    setLoading(true);
    currentYearCacher = currentYear;
    setTimeout(() => setLoading(false), 20);
  }, [currentYear]);

  // Extract unique years from data and update the state
  useEffect(() => {
    const uniqueYears = extractYears(fullDataRows);
    if (JSON.stringify(uniqueYears) !== JSON.stringify(setUniqueYears)) {
      setUniqueYears(uniqueYears);
    }
    setCurrentYear(currentYearCacher || uniqueYears.length - 1);
  }, [results]);

  useEffect(() => {
    if (typeof defaultFilters === "object" && defaultFilters.wtt !== (wtt === "Included")) {
      setWtt(defaultFilters.wtt ? "Included" : "Excluded");
    }
  }, [defaultFilters]);

  function conv2months(form, results, chartPropsindex, decimalplaces = 2) {
    const formattedData = [];
    const siteCache = new Map();

    results.forEach((entryx) => {
      const entry = { ...entryx };
      let name;
      const dateMoment = moment(entry.date, timeFormat);
      const siteInfo = siteCache.get(entry.siteId) || findSite(entry.siteId);
      siteCache.set(entry.siteId, siteInfo);

      if (xaxis === XAXIS_OPTIONS[0]) name = dateMoment.format("MMM YYYY");
      else if (xaxis === XAXIS_OPTIONS[1]) name = getResultDates(entry).fiscalYear;
      else name = Number(dateMoment.format("YYYY"));

      if (type === "Market") {
        entry.result = entry.marketbased_result;
        entry.wtt = entry.marketbased_wtt;
      }

      if (wtt === "Excluded") entry.wtt = 0;

      formattedData.push({
        ...entry,
        name,
        "tCO₂e": (entry.result ?? 0) + (entry.wtt ?? 0),
        kWh: entry.kwh ?? 0,
        Count: 1,
        siteCountry: siteInfo.country,
        site: siteInfo.title,
      });
    });

    return xaxis === XAXIS_OPTIONS[0] ? fillMissingMonths(formattedData) : formattedData;
  }

  results = conv2months(form, results);

  let graphBarNames = getNextTwelveMonths(firstCompany()?.reporting_year_starting_month, uniqueYears[currentYear], "YYYY", "MMM").map((obj) => {
    return obj.month + " " + obj.year;
  });
  if (xaxis !== XAXIS_OPTIONS[0]) graphBarNames = uniqueYears;

  console.log("Data: ", results);
  const graphDataMap = results.reduce((acc, item) => {
    acc[item.name] = acc[item.name] || [];
    acc[item.name].push(item);
    return acc;
  }, {});

  const graphDataRaw = graphBarNames.map((monthName) => {
    const filteredData = graphDataMap[monthName] || [];
    const result = { name: monthName };

    filteredData.forEach((curr) => {
      let typeKey = yaxis;
      if (segregator !== "none" && curr[segregator]) {
        typeKey = curr[segregator];
      }
      result[typeKey] = (result[typeKey] || 0) + Number(curr[yaxis]);
    });

    for (const key in result) {
      if (key !== "name" && typeof result[key] === "number") {
        result[key] = fixDecimals(result[key], 2);
      }
    }

    return result;
  });

  const graphData = cleanArray(graphDataRaw, [undefined, null, NaN]);

  const renderStackedBarChart = React.useCallback(() => {
    let types = [...new Set(results.map((item) => item[segregator]))];
    if (segregator === "none") types = [yaxis];

    return (
      <BarChart ResponsiveContainer margin={{ bottom: 50, left: 40, top: 10, right: 30 }} data={graphData}>
        <CartesianGrid />
        <XAxis dataKey="name" fontSize={11} interval={0}>
          <Label value={xaxis} position="insideBottom" offset={-10} fill="#000000" style={{ textDecoration: "underline" }} />
        </XAxis>
        <YAxis>
          <Label value={yaxis} angle={-90} position="insideLeft" offset={-20} fill="#000000" style={{ textDecoration: "underline" }} />
        </YAxis>
        <Tooltip />
        <Legend verticalAlign="top" height={36} />
        {types
          .filter((x) => ![undefined, null].includes(x))
          .map((type, index) => {
            let name = type;
            if (segregator === "type") {
              // show category with name as well.
              const category = filterFactors({ "Level 3": name }, "Level 2")[0];
              name = category + " - " + name;
              try {
                name = name.replace(/\(.*?\)/g, "").trim();
              } catch (err) {}
            }
            if (!itemColorMapping[type]) {
              const colorIndex = Object.keys(itemColorMapping).length % graph_colors.length;
              itemColorMapping[type] = graph_colors[colorIndex];
            }
            return <Bar key={type + index} dataKey={type} stackId="a" fill={itemColorMapping[type]} name={name} />;
          })}
      </BarChart>
    );
  }, [graphData, yaxis, xaxis, segregator, itemColorMapping]);

  const chartElement = segregator === "nonee" ? renderStackedBarChart() : renderStackedBarChart();

  if (!results?.length) return <Empty description="Graph Construction Error: No Data Detected" style={{ background: "white", padding: 20 }} />;

  const segrigate_filters = [
    { key: "Default", value: "none" },
    {
      key: form?.name === "hotel" ? "Site Country" : "Country",
      value: "siteCountry",
    },
    { key: "Site", value: "site" },
    ...segregate_by,
  ].filter(({ value }) => {
    // hide options if they will have same value eg all results have country United Kingdom then hide country
    if (value !== "none") {
      const valuesForOption = removeDuplicatesFast(
        results.map((result) => {
          if (value === "siteCountry") {
            return findSite(result.siteId).country;
          }
          return result[value];
        })
      ).filter((x) => x);
      if (valuesForOption.length < 2) return false;
    }
    return true;
  });
  console.log(segrigate_filters, "segrigate_filters");

  return (
    <Spin spinning={loading} tip="Please wait while we build the chart.">
      {heading && <Divider>{heading}</Divider>}
      <div
        style={{
          background: theme.useToken().token.colorBgContainer,
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <div className="navigation-arrows">
            {xaxis === XAXIS_OPTIONS[0] && (
              // only display this if xaxis is set to months
              // hide if one year option only i.e. all are in 2024
              <Select1
                hideIfOneOptionOnly
                minWidth={"105px"}
                title="Year"
                value={uniqueYears[currentYear]}
                setValue={(year) => setCurrentYear(uniqueYears.findIndex((x) => x === year))}
                options={uniqueYears}
              />
            )}
            {Array.isArray(segregate_by) && (
              <div className="navigation-arrows">
                <Select1 hideIfOneOptionOnly minWidth={"105px"} title={<>Breakdown by</>} value={segregator} setValue={setSegrigator} options={segrigate_filters} />
              </div>
            )}
            {form.hasWTT !== false && <Select1 hideIfOneOptionOnly minWidth={isMobile ? "39px" : "105px"} title="WTT" value={wtt} setValue={setWtt} options={["Included", "Excluded"]} />}
            {form.name === "electricity" && <Select1 hideIfOneOptionOnly minWidth={isMobile ? "39px" : "105px"} title="Type" value={type} setValue={setType} options={["Location", "Market"]} />}
            <Select1 hideIfOneOptionOnly minWidth={isMobile ? "39px" : "105px"} title="Y Axis" value={yaxis} setValue={setYaxis} options={YAXIS_OPTIONS} />
            <Select1 hideIfOneOptionOnly title="X Axis" minWidth={isMobile ? "39px" : "105px"} value={xaxis} setValue={setXaxis} options={XAXIS_OPTIONS} />
          </div>

          <DownloadDomImage
            fileName={`Chart Export ${firstCompany()?.title}.png`}
            elementId={`bar-rechart-image-${JSON.stringify(results)}`}
            text={"Graph of Results: " + getFormName(form?.name) + ", Year: " + uniqueYears[currentYear] + "\nCreated by Sustrax MX from CarbonFootprint.com"}
          />
        </div>
        <div style={{ overflowX: "auto" }}>
          <div style={{ position: "relative", padding: 10, width: isMobile ? "1200px" : "auto", overflowX: "auto" }}>
            <ResponsiveContainer id={`bar-rechart-image-${JSON.stringify(results)}`} aspect={6}>
              {chartElement}
            </ResponsiveContainer>
          </div>
        </div>
        <Modal title="Graph Enlarged View" footer={null} onCancel={() => setLarged(false)} style={{ height: "100vh" }} open={larged} width="100wh">
          <ResponsiveContainer aspect={1}>{chartElement}</ResponsiveContainer>
        </Modal>
      </div>
    </Spin>
  );
}
