import React, { useEffect, useState } from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import { findSite } from "../../../helper/attribute";
import { genPut } from "../helper/create";
import CountrySelect from "../../../components/select/country";
import { isOfflineUser } from "../../../offline";
import SelectSearch from "../../../components/select/SearchSelect";

export const INPUTS = (data, setData, formname) => {
  if (isOfflineUser())
    return [
      {
        key: "country",
        elem: CountrySelect,
      },
      {
        key: "q1", // do you know your supplier factors
        elem: SelectSearch,
        options: ["Yes", "No"],
        title: "Do you know your energy provider's emissions per kWh or are you on a renewable tariff?",
        description: " ",
        set: {
          supplier_factors: 0, // to avoid people leving it blank, make it null if No selected
        },
      },
      {
        key: "supplier_factors",
        elem: NumberInput,
        hidden: data.q1 === "No",
        min: 0,
      },
      {
        key: "amount",
        elem: NumberInput,
      },
    ];
  return [
    {
      key: "date",
      elem: DateInput,
    },
    {
      key: "supplier_factors",
      elem: NumberInput,
      min: 0,
    },
    {
      key: "amount",
      elem: NumberInput,
    },
  ];
};

export default function Electricity({ data, setData }) {
  const formname = "electricity";
  const [SITEUPDATECOUNT, setSITEUPDATECOUNT] = useState(0);
  useEffect(() => {
    setData({
      ...data,
      q1: isOfflineUser() ? "No" : "Yes",
      supplier_factors: !SITEUPDATECOUNT && data.hasOwnProperty("supplier_factors") ? data["supplier_factors"] : findSite(data.siteId).supplier_factors ?? null,
    });
    setSITEUPDATECOUNT(SITEUPDATECOUNT + 1);
  }, [data.siteId]);

  return <>{INPUTS(data, setData, formname).map((input) => genPut(input, formname, data, setData))}</>;
}
