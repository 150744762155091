import React, { useState } from "react";
import { Row, Col } from "antd";
import { ToWords } from "to-words";
import WhyOffsetWithUs from "./offsetPageOfflineComponents/WhyOffsetWithUs";
import OffsetCalculatorOffline from "./offsetPageOfflineComponents/OffsetCalculator";
import FAQ from "./offsetPageOfflineComponents/FAQ";
import { fixDecimals } from "../../../../helper/wodash";

const OffsetPageOffline = ({ data, offsetPage }) => {
  if (!Array.isArray(data)) data = [];

  const [selectedValues, setSelectedValues] = useState(data.map((item) => item));
  const [customValue, setCustomValue] = useState(null);
  const toWords = new ToWords();

  const totalFromCheckboxes = fixDecimals(selectedValues.reduce((acc, item) => acc + Number(item.value), 0));

  const handleCheckboxChange = (item) => {
    setSelectedValues((prevValues) => {
      const exists = prevValues.find((val) => val.title === item.title);
      if (exists) {
        return prevValues.filter((val) => val.title !== item.title);
      } else {
        return [...prevValues, item];
      }
    });
  };

  const getOffsetText = (value) => {
    if (!value || value <= 0) return "";
    const words = toWords.convert(value, { currency: false });
    return <div style={{ fontSize: "14px", color: "#666", marginTop: "4px" }}>{`${words} (${value}) metric tonnes of CO₂e`}</div>;
  };

  const c = (
    <OffsetCalculatorOffline
      offsetPage={offsetPage}
      data={data}
      selectedValues={selectedValues}
      handleCheckboxChange={handleCheckboxChange}
      totalFromCheckboxes={Math.ceil(totalFromCheckboxes)}
      customValue={Math.ceil(customValue)}
      setCustomValue={setCustomValue}
      getOffsetText={getOffsetText}
    />
  );
  if (offsetPage) {
    return c;
  }
  return (
    <div style={{ background: "#f0f2f5", minHeight: "100vh", maxHeight: "100vh" }}>
      <Row gutter={[24, 24]} justify="center">
        <Col xs={24} lg={24}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>{c}</Col>
            <Col xs={24}>
              <WhyOffsetWithUs />
              <FAQ />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default OffsetPageOffline;
