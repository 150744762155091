import React from "react";
import moment from "moment";

import { Card, Table, Collapse, Row, Col, Typography, Tooltip } from "antd";
import store from "../../../../redux/store";
import { useSelector } from "react-redux";
import { selectResults } from "../../../../redux/features/appSlice";
import { getForms } from "../../../../static/formRoutes";
import { getDetails } from "../../../results/CombinedTable";
import { timeFormat } from "../../../../helper/time";
import { DeleteOutlined, LinkOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { deleteResult } from "../../../../firebase/data/delete";
import { fetchResults } from "../../../../firebase/data/get";
import { Link } from "react-router-dom";
import { routePath } from "../../../../static/routes.static";
import { getEmissionsForOffline, getTotalEmissionsForOffline } from "../../../results/offline_results_table_in_form";
const { Title } = Typography;

const { Panel } = Collapse;
// Constants
const TABLE_COLUMNS = [
  {
    title: "Date Added",
    dataIndex: "createdAt",
    key: "createdAt",
    width: "15%",
    render: (date) => date,
  },
  {
    title: "Emissions (tCO₂e)",
    dataIndex: "result",
    key: "result",
    width: "15%",
    render: (_, record) => getEmissionsForOffline(record),
  },
  {
    title: "Details",
    dataIndex: "text",
    key: "text",
    width: "40%",
    render: (_, record) => getDetails(record.name, record)?.report,
  },
  {
    title: "Comments",
    dataIndex: "comment",
    key: "comment",
    width: "30%",
    render: (text) => (
      <Tooltip title={text || "-"}>
        <Typography.Text
          type="secondary"
          ellipsis
          style={{ maxWidth: "300px", display: "block" }}
        >
          {text || "-"}
        </Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: "Options",
    key: "options",
    width: "10%",
    render: (_, record) => (
      <Popconfirm
        title="Are you sure you want to delete this result?"
        onConfirm={() => {
          deleteResult(record.id);
          fetchResults(record.name);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button type="text" danger icon={<DeleteOutlined />} />
      </Popconfirm>
    ),
  },
];


const formatNumber = (num) => {
  if (num === undefined) return "0.00";
  return Number(num)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// Main component
export const DisplayResultsTableData = () => {
  const results = useSelector(selectResults);

  const renderFormTable = (form) => {
    const resultsFiltered = results.filter(
      (result) => result.name === form.name
    );

    return (
      <div key={form.title} style={{ marginBottom: "30px" }}>
        <Title level={3} style={{ marginBottom: "10px" }}>
          <Link style={{ color: "black" }} to={routePath.Form(form.name)}>
            {form.title}
          </Link>
        </Title>
        <Table
          columns={TABLE_COLUMNS}
          dataSource={resultsFiltered}
          pagination={false}
          locale={{
            emptyText: (
              <div>
                No results found. Input it{" "}
                <Link to={routePath.Form(form.name)}>here</Link>.
              </div>
            ),
          }}
          size="small"
          bordered
          rowKey={(record) => `${form.name}-${record.result}-${record.comment}`}
        />
      </div>
    );
  };

  return (
    <div style={{ padding: "20px" }}>{getForms().map(renderFormTable)}</div>
  );
};
