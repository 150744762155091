import { doc, writeBatch } from "firebase/firestore";
import { db } from "../../firebase/setup";
import { firestoreStatic } from "../../static/firestore.static";
import { catcher } from "../../firebase/util";
import { setResults } from "../../redux/features/appSlice";
import store from "../../redux/store";
import { findForm } from "../../static/formRoutes";

export const bulkUpdateRows = async (
  dataRows,
  dispatch,
  setLoading = () => {}
) => {
  console.log(dataRows);
  if (!window.confirm("Are you sure you want to update the data?")) {
    return;
  }

  setLoading(true);

  try {
    const form = findForm(dataRows[0]?.name);
    const overwriteResults = await Promise.all(
      dataRows.map(async (row) => {
        const newResult = await form.pack(JSON.parse(JSON.stringify(row)));
        return newResult;
      })
    );
    console.log(overwriteResults);

    await catcher(
      async () => {
        const batch = writeBatch(db);
        overwriteResults.forEach((result) => {
          const ref = doc(db, firestoreStatic.results, result?.id);
          batch.update(ref, result);
        });
        await batch.commit();
        dispatch(
          setResults(
            store.getState().app.results.map((result) => {
              const updatedResult = overwriteResults.find(
                (prevResultRow) => prevResultRow.id === result.id
              );
              return updatedResult || result;
            })
          )
        );
      },
      { setLoading }
    );
  } catch (error) {
    console.error("Error updating rows:", error);
  } finally {
    setLoading(false);
  }
};
