import { Button, Empty, message, Modal, Steps, theme, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { T1 } from "../../appconfig/texts";
import Loading from "../../components/loading";
import SelectForm from "../../components/select/selectform";
import FormContext from "../../contexts/FormContext";
import { createFormData } from "../../firebase/data/create";
import { fetchResults } from "../../firebase/data/get";
import { updateFormData } from "../../firebase/data/update";
import { catcher, except } from "../../firebase/util";
import { findResult, firstSite } from "../../helper/attribute";
import { getCurrentPlan, isSustraxLite, planValue } from "../../helper/plans";
import { timeFormat } from "../../helper/time";
import { selectFactors, selectResults, setResults } from "../../redux/features/appSlice";
import store from "../../redux/store";
import { findForm, findNextForm, findPrevForm, fixedDataFields } from "../../static/formRoutes";
import { routePath } from "../../static/routes.static";
import { changeHasChanged } from "./helper/create";
import { inputAllowed } from "./helper/getter";
import InputDataPage from "./inputdata";
import ReviewPage from "./reviewpage";
import { randomFormData } from "./helper/random";
import { getMetrics } from "./comp/metricsInput";
import { ArrowLeftOutlined, ArrowRightOutlined, CalculatorOutlined, EditOutlined, LockOutlined, RightOutlined } from "@ant-design/icons";
import { isOfflineUser } from "../../offline";
import OfflineResultsTableInForm from "../results/offline_results_table_in_form";
import useSize from "../../hooks/useSize";
import { random } from "lodash";
let formSubmitted = false;

export default function DataForm() {
  const plan = getCurrentPlan();
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams, setQueryParams] = useState(null);
  const factors = useSelector(selectFactors);
  const [data, setData] = useState({});
  const [mode, setMode] = useState(); //in testing mode, we randomly fill data
  const [editId, setEditId] = useState(null); //used in edit mode
  const [messages, setMessages] = useState({ no_site: false });
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [optionsData, setOptionsData] = useState({}); //data used to show before saving

  const { isMobile } = useSize();
  const results = useSelector(selectResults);
  const dispatch = useDispatch();
  const [inputKey, setKey] = useState(0);

  const getSpreadMonths = (
    date = data.date,
    months = optionsData.spreadMonths,
    format = "MMM YY",
    reverse = optionsData.spreadPos !== "previous"
    //if true it will add months
  ) => {
    return Array.from({ length: months }, (_, index) => {
      const resultDate = reverse ? moment(date, "DD/MM/YYYY").add(index, "months") : moment(date, "DD/MM/YYYY").subtract(index, "months");
      const result = resultDate.format(format);
      return result;
    });
  };

  const getData = (data) => {
    console.log("Getting Data", data, optionsData);
    let temp = JSON.parse(JSON.stringify(data));
    if (data.file) temp.file = data.file;

    if (optionsData.scaletag) {
      // now re-set all those things which would be affected by the scale
      const mul = optionsData.scale_scaleBy / optionsData.scale_numBase;
      if (isNaN(mul)) {
        message.error("Not a number");
        return temp;
      }
      let dataFields = findForm(data.name)?.dataFields(data);
      dataFields = [...dataFields, ...fixedDataFields];
      const metrics = getMetrics(data);
      if (optionsData.scale_metrics && Array.isArray(metrics)) {
        dataFields = [...dataFields, ...metrics];
      }
      dataFields.forEach((key) => {
        temp[key] = temp[key] * mul;
      });
      temp = findForm(data.name)?.pack(temp);
    }

    if (optionsData.newsupplierfactortag) {
      // use the temp one
      console.log("changing temp");
      // keeep it above because newghgtag updates the data in packing
      temp = { ...findForm("electricity")?.pack(temp, false, optionsData) };
    }
    if (optionsData.newghgtag) {
      // if ghg has been overwritten
      Object.keys(temp).forEach((key) => {
        if (key.includes("ghg") && !key.includes("tnd")) {
          temp[key] = optionsData.newghgtag;
          if (key.includes("wtt") && [1, 2, 3, 4, 5].includes(optionsData.newghgtag)) {
            temp[key] = 8; // 3.3fuel and energy related activities
          }
        }
      });
    }

    return { ...temp, ...optionsData }; //saving them all in one place for now
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setQueryParams(Object.fromEntries(searchParams.entries()));
    changeHasChanged(false);
    formSubmitted = false;
  }, [location.search]);

  useEffect(() => {
    if (queryParams && results) {
      if (!queryParams.name) {
      } else {
        const firstSiteId = firstSite({ editableSiteFilter: true })?.id;
        if (!firstSiteId) return setMessages({ ...messages, no_site: true });
        //two modes edit and other
        let initialData = {
          name: queryParams.name,
          siteId: firstSiteId,
        };
        // if (mode === "testing") initialData = { ...initialData, ...randomFormData(initialData.name) };
        if (queryParams.mode === "edit") {
          const editId = queryParams.id;
          setEditId(editId);
          let [optionsData, result] = findResult(queryParams.id, true);
          // need to seperate settings and results
          console.log("editing", optionsData, result);
          console.log(result, "result to set before");

          //--------SCALE---------
          // we need this just for scale i guess. todo: check if this needed for others.
          if (editId && optionsData.scaletag) {
            //simulate multiplication back.
            const temp = JSON.parse(JSON.stringify(result));
            const mul = optionsData.scale_numBase / optionsData.scale_scaleBy;
            if (isNaN(mul)) {
              message.error("Not a number");
              return temp;
            }
            let dataFields = findForm(result.name)?.dataFields(result);
            dataFields = [...dataFields, ...fixedDataFields];
            const metrics = getMetrics(result);
            if (optionsData.scale_metrics && Array.isArray(metrics)) {
              dataFields = [...dataFields, ...metrics];
            }
            dataFields.forEach((key) => {
              temp[key] = temp[key] * mul;
            });
            result = temp;
            console.log(temp, "result to set after");
          }
          console.log(result, "result to set");
          setData({
            ...initialData,
            ...result,
          });
          setOptionsData(optionsData);
        } else {
          setData({
            ...initialData,
            date: !planValue("canSelectCustomDate") ? moment().format(timeFormat) : null,
          });
        }
        setCurrent(1);
      }
    }
  }, [queryParams, results]);
  const steps = [
    {
      title: <T1 pos="form.steps.select" />,
      content: <SelectForm data={data} setData={setData} editId={editId} />,
    },
    {
      title: <T1 pos="form.steps.inp" />,
      content: (
        <FormContext.Provider value={{ error_message: data?.err }}>
          <InputDataPage
            key={inputKey}
            data={data}
            setData={(newdata) => {
              // for (let [key, value] of Object.entries(newdata)) {
              //   addOrReplaceQueryParam(key, value);
              // }

              setData(newdata);
            }}
            editId={editId}
          />
        </FormContext.Provider>
      ),
    },
    {
      title: <T1 pos="form.steps.review" />,
      content: <ReviewPage data={data} optionsData={optionsData} setOptionsData={setOptionsData} getData={getData} getSpreadMonths={getSpreadMonths} editId={editId} />,
    },
  ];
  const { token } = theme.useToken();
  const next = () => {
    if (current === 0 && !data.name) {
      message?.error("Please select one form");
    } else if (current === 1) {
      // save data

      catcher(
        async () => {
          try {
            let newData = await findForm(data.name).pack(data);
            console.log("NEW DATA", newData);
            delete data["err"]; // no prob in packing data
            delete newData["err"];
            if (!newData.result) newData.result = 0;
            console.log("Data (old and new)", data, newData);
            newData = { ...data, ...newData };
            setData(newData);
            // if current plan is free, don't show the skip page
            if (isOfflineUser()) {
              return await handleSubmit(newData)
              Modal.confirm({
                title: "Confirm add to your results? ",
                content: "Please confirm you would like to add this to your emissions results. You can delete and re-add it later.",
                onOk: async () => await handleSubmit(newData),
                okText: "Confirm",
              });
              return;
            }
            if (getCurrentPlan() === "free" || getCurrentPlan() === "basic") {
              if (editId) return handleUpdate("calculation pending");
              Modal.confirm({
                title: "Confirm add to your results?",
                content: "Please confirm you would like to add this to your emissions results.",
                onOk: async () => await handleSubmit(newData),
                okText: "Confirm",
              });
              return;
            } else {
              setCurrent(current + 1);
            }
          } catch (err) {
            setData({ ...data, err: err });
            throw err;
          }
        },
        { setLoading, success_msg: "" }
      );
    } else {
      setCurrent(current + 1);
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const cancelUpdate = () => {
    if (getCurrentPlan() === "offline") return navigate(routePath.combined_data_table);
    navigate(routePath.Result(data.name));
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    minHeight: "40vh",
    padding: "10px",
    backgroundColor: token.colorBgContainer,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  const handleSubmit = async (finalData = data) => {
    console.log(finalData, "submitting");
    finalData = getData(finalData);
    console.log("Submitting", finalData);
    await catcher(
      async () => {
        // plan check
        if (!inputAllowed(finalData)) {
          Modal.info({
            title: "Max Inputs Reached",
            content: "You have reached Maximum Number of Inputs for your plan.",
          });
          throw "Max Inputs Reached";
        }
        const result = await createFormData(finalData);
        dispatch(setResults([result, ...store.getState().app.results]));

        // if offline user no need to navigate.
        if (isOfflineUser()) {
          setKey(inputKey + 1+ random(10000));
          return;
        }
        formSubmitted = true; // no purpose of this in life
        if (planValue("canViewResults")) {
          navigate(routePath.Result(finalData.name));
        } else {
          navigate(routePath.combined_data_table);
        }
      },
      { setLoading }
    );
  };
  const handleUpdate = (calculation_pending = false) => {
    catcher(
      async () => {
        if (calculation_pending) {
          //updating new data here as well if we dont go to review page
          let newData = await findForm(data.name).pack(data);
          console.log("updating data to ", newData);
          delete data["err"]; // no prob in packing data
          delete newData["err"];
          if (!newData.result) newData.result = 0;
          newData = { ...data, ...newData };
          setData(newData);
          await updateFormData(queryParams.id, getData(newData), true);
        } else {
          await updateFormData(queryParams.id, getData(data), true);
          console.log("test108", getData(data));
        }
        //updating new data here as well if we dont go to review page
        fetchResults(); //todo: improve update
        // go back to the source, result or combined_data_table
        let source = queryParams.source ?? "result";
        if (source === "result") {
          return navigate(routePath.Result(data.name));
        } else if (source === "combined_data_table") {
          return navigate(routePath.combined_data_table);
        }
      },
      { setLoading }
    );
  };

  const nextForm = findNextForm(data.name);
  const prevForm = findPrevForm(data.name);

  if (!factors) return <Loading title={"Loading Emission Factors"} />;
  if (messages.no_site) return <Empty description="No site found! Please contact company admins." />;
  return (
    <div
      className="input-form"
      style={{
        position: "relative", // Ensure parent is positioned relative
      }}
    >
      {!inputAllowed({ name: data.name }) && (
        <div
          style={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.8)", // Semi-transparent background
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
            zoom: "1.4",
            textAlign: "center",
            zIndex: 10, // Ensure it appears above other content
          }}
        >
          <LockOutlined twoToneColor={"darkgreen"} /> Record limit reached
          <br />
          {nextForm && (
            <Button style={{color:"forestgreen"}} size="small" type="link" onClick={() => navigate(routePath.Form(nextForm.name))}>
              Next to {nextForm.title}
              <ArrowRightOutlined />
            </Button>
          )}
        </div>
      )}
      {editId ? (
        <>
          <Typography.Title level={2}>{findForm(data.name).title}</Typography.Title>
          <Typography.Title level={3}>
            <EditOutlined /> Editing Form Data
          </Typography.Title>
        </>
      ) : (
        ""
      )}
      <div style={!inputAllowed({ name: data.name }) ? { filter: "blur(1px)", pointerEvents: "none" } : {}}>
        {!inputAllowed({ name: data.name }) && <div></div>}
        <Steps current={current} items={items} />

        {current != 0 && (
          <div>
            <img
              className="ani"
              style={{
                display: "none",
                position: "fixed",
                right: "50%",
                maxWidth: "200px",
                bottom: "50%",
                transform: "translate(100%,100%)",
                opacity: "100%",
              }}
              src={findForm(data.name)?.bg}
            ></img>
          </div>
        )}
        <div style={contentStyle}>{steps[current].content}</div>
      </div>
      <div
        style={{
          marginTop: 24,
          display: isOfflineUser() ? "none" : "",
        }}
      >
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" style={{ minWidth: "90px" }} onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 &&
          (editId ? (
            <Button type="primary" onClick={() => handleUpdate("calculation pending")} loading={loading}>
              Update Data
            </Button>
          ) : (
            <Button type="primary" onClick={() => handleSubmit(data)} loading={loading}>
              Done
            </Button>
          ))}

        {editId && (
          <>
            {" "}
            <span style={{ color: "grey" }}>-or-</span>
            <Button
              type="dashed"
              style={{
                margin: "0 8px",
                border: "1px dashed lightgrey",
              }}
              onClick={() => cancelUpdate()}
            >
              Cancel Update
            </Button>
          </>
        )}
      </div>
      <div
        style={{
          display: isOfflineUser() ? "" : "none",
          padding: 10,
          width: "100%",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: isMobile ? "column" : "", gap:"10px" }}>
          <Button
            onClick={() => {
              if (prevForm) {
                navigate(routePath.Form(prevForm.name));
              } else {
                navigate(routePath.Dashboard);
              }
            }}
          >
            <ArrowLeftOutlined />
            {prevForm ? "Back to " + prevForm?.title : "Back to dashboard"}
          </Button>
          <Button type="primary" onClick={next} disabled={!inputAllowed(data)}>
            <CalculatorOutlined />
            Calculate & add to footprint
          </Button>

          {/*Next or skip buttons for offline users */}
          <div style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
            {/* Conditional "Next" button */}
            {nextForm && (
              <Button onClick={() => navigate(routePath.Form(nextForm.name))}>
                Next to {nextForm.title}
                <ArrowRightOutlined />
              </Button>
            )}

            {/* Always show "Go to results" button */}
            <Button type="link" onClick={() => navigate(routePath.Analysis("Years"))}>
              Go to results
            </Button>
          </div>
        </div>
        <Typography.Title level={3}>Results</Typography.Title>
        <OfflineResultsTableInForm name={data.name} setKey={setKey} />
      </div>
    </div>
  );
}
