import { CaretDownFilled } from "@ant-design/icons";
import { Select, Typography } from "antd";
import React, { useState } from "react";
import { findCompanies, openSites } from "../../helper/attribute";
import FakeLabel from "../input/select";
import { isOfflineUser } from "../../offline";

function filterSites(companyId) {
  const sites = openSites({ editableSiteFilter: true });
  return sites.filter((site) => site.companyId === companyId);
}

function optionsSelectSites() {
  // Show only companies that have sites present
  return findCompanies().map((company, i) => {
    return {
      label: company.title,
      key: company.id,
      options: filterSites(company.id).map((site, i) => ({
        label: site.title,
        value: site.id,
        key: site.id,
      })),
    };
  });
}

export default function SelectSites({ value, handleSelect, elemOnly }) {
  const [data] = useState(optionsSelectSites());

  // no need to show sites if offline
  if (isOfflineUser()) return null;

  const filterOption = (input, option) => {
    return option.label.toLowerCase().includes(input.toLowerCase());
  };

  const elem = (
    <Select
      showSearch
      style={{ fontSize: "10px" }}
      dropdownRender={(elem) => {
        return <div className="notranslate">{elem}</div>;
      }}
      onChange={handleSelect}
      value={value}
      options={data}
      filterOption={filterOption}
      suffixIcon={
        <Typography.Text>
          <CaretDownFilled />
        </Typography.Text>
      }
    />
  );

  if (elemOnly) return elem;
  return (
    <div>
      <FakeLabel
        title={"Select site 🌿"}
        elem={elem}
        style={{ border: "1px solid darkorange" }}
        shouldOptionTranslate="no"
      />
    </div>
  );
}
