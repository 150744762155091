// Exported JSON object
const instructionsData = {
    "fuel": {
      "instructions": "Enter your consumption of each type of household fuel and press the Calculate button. (Vehicle fuels can be entered later in the Cars input)"
    },
    "naturalgas": {
      "instructions": "Enter your consumption and then press the Calculate button."
    },
    "electricity": {
      "instructions": "Enter the amount of household electricity and then press the Calculate button."
    },
    "cars": {
      "instructions": "Enter your cars details, mileage or fuel for the year and click the calculate button."
    },
    "flight": {
      "instructions": "If you know the destination and departure airports, use the 'select airports' option. If you have your total air miles travelled, use 'enter distances' option."
    },
    "other_travels": {
      "instructions": "Enter details for taxi, bus, rail, and ferry travel and click the calculate button."
    },
    "spending": {
      "instructions": "Please enter your amount of spend for each category below, and then press the calculate button to estimate your secondary carbon footprint."
    }
  };
  
  // Function to get instructions by name
  export function getOfflineFormInstructions(name) {
    if (instructionsData[name]) {
      return instructionsData[name].instructions;
    } else {
      return "";
    }
  }
  