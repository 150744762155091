import { DownOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import { Card, Divider, Input, Skeleton, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../../ErrorBoundary";
import { T1 } from "../../../appconfig/texts";
import { getAppInfo } from "../../../firebase/appinfo/create";
import { catcher } from "../../../firebase/util";
import { getCurrentPlan, isSustrax, isSustraxLite } from "../../../helper/plans";
import useSize from "../../../hooks/useSize";
import { selectOther, setOther } from "../../../redux/features/appSlice";
import { isOfflineUser } from "../../../offline";

const { Title, Text } = Typography;
const { Search } = Input;

const FAQItem = ({ question, answer, onlypro = false, onlyannual = false, hidevita = false, onlyvita=false }) => {
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  if (onlypro && getCurrentPlan() !== "pro") {
    return null;
  }
  if (onlyannual && !isSustraxLite()) {
    //if not lite annual
    return null;
  }

  if (hidevita && isOfflineUser()) {
    return null;
  }
  if (onlyvita && !isOfflineUser()) {
    return null;
  }

  return (
    <Card style={{ marginBottom: 16, marginTop: 0, minHeight: "100%" }} onClick={toggleCollapse} hoverable bordered={false}>
      <Title
        style={{
          fontSize: isExtraSmall ? "14px" : "16px",
          marginBottom: 0,
          marginTop: 0,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {question}
        {collapsed ? <DownOutlined /> : <UpOutlined />}
      </Title>
      {!collapsed && (
        <>
          <Divider />
          <pre
            style={{
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {answer}
          </pre>
        </>
      )}
    </Card>
  );
};

const FAQPage = ({ data }) => {
  const size = useSize(); // Assuming useSize hook returns an object with size booleans
  const dispatch = useDispatch();
  let faqData = useSelector(selectOther("faq")) || []; // Providing a default value to avoid undefined
  if (data) faqData = data;
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFAQData = async () => {
      try {
        const faq = await getAppInfo("faq");
        dispatch(setOther({ key: "faq", value: faq.data }));
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch FAQ data:", error);
        setLoading(false); // Ensure loading is set to false even if there is an error
      }
    };

    if (!faqData || faqData.length === 0) {
      fetchFAQData();
    } else {
      setLoading(false); // Data is already available; no need to load
    }
  }, [data, dispatch]);

  if (loading || !faqData || faqData.length === 0) return <Skeleton />;

  const filteredFAQs = faqData
    .map((category) => ({
      ...category,
      items: category.items.filter((faq) => faq.question.toLowerCase().includes(searchTerm.toLowerCase()) || faq.answer.toLowerCase().includes(searchTerm.toLowerCase())),
    }))
    .filter((category) => category.items.length > 0);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  return (
    <div>
      <ErrorBoundary>
        <Title style={{ fontSize: size.isExtraSmall ? "24px" : "38px" }}>FAQ</Title>
        <Search size={size.isExtraSmall ? "middle" : "large"} placeholder="Search FAQs" allowClear enterButton={<SearchOutlined />} onChange={(e) => handleSearch(e.target.value)} />
        {filteredFAQs.map((category, index) => (
          <div key={index} style={isOfflineUser() ? { display: category.category.includes("AI Recommendations") ? "none" : "" } : {}}>
            <Title style={{ color: "forestgreen", fontSize: size.isExtraSmall ? "18px" : "24px", marginBottom: "10px" }}>{category.category}</Title>
            {category.items.map((faq, faqIndex) => (
              <FAQItem key={faqIndex} question={faq.question} answer={faq.answer} {...faq} />
            ))}
          </div>
        ))}
      </ErrorBoundary>
    </div>
  );
};

export default FAQPage;
