import React, { useEffect, useState } from "react";
import { Typography, Input, Button, Divider, message, Checkbox } from "antd";
import FAQPage from "../../extras/faq/FAQ";
import ErrorBoundary from "../../../ErrorBoundary";
import { catcher } from "../../../firebase/util";
import { getAppInfo, updateAppInfo } from "../../../firebase/appinfo/create";
import { InputNumber, Space, Card } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import Loading from "../../../components/loading";

const { Text } = Typography;

function Editor({ data, setData }) {
  // Function to add a new category
  const addCategory = () => {
    const newCategory = {
      category: "New Category",
      items: [],
    };
    setData([...data, newCategory]);
  };

  // Function to delete a category
  const deleteCategory = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  // Function to add a new question and answer
  const addQA = (categoryIndex) => {
    const newQA = {
      question: "New Question",
      answer: "New Answer",
    };
    const newData = [...data];
    newData[categoryIndex].items.push(newQA);
    setData(newData);
  };

  // Function to delete a question and answer
  const deleteQA = (categoryIndex, qaIndex) => {
    const newData = [...data];
    newData[categoryIndex].items.splice(qaIndex, 1);
    setData(newData);
  };
  const moveQA = (categoryIndex, qaIndex, direction) => {
    const newData = [...data];
    const currentQA = newData[categoryIndex].items[qaIndex];

    if (direction === "up" && qaIndex > 0) {
      // Swap the current item with the one above it
      newData[categoryIndex].items[qaIndex] =
        newData[categoryIndex].items[qaIndex - 1];
      newData[categoryIndex].items[qaIndex - 1] = currentQA;
    } else if (
      direction === "down" &&
      qaIndex < newData[categoryIndex].items.length - 1
    ) {
      // Swap the current item with the one below it
      newData[categoryIndex].items[qaIndex] =
        newData[categoryIndex].items[qaIndex + 1];
      newData[categoryIndex].items[qaIndex + 1] = currentQA;
    }

    setData(newData);
  };
  const moveCategory = (categoryIndex, direction) => {
    const newData = [...data];

    if (direction === "up" && categoryIndex > 0) {
      // Swap the current category with the one above it
      const currentCategory = newData[categoryIndex];
      newData[categoryIndex] = newData[categoryIndex - 1];
      newData[categoryIndex - 1] = currentCategory;
    } else if (direction === "down" && categoryIndex < newData.length - 1) {
      // Swap the current category with the one below it
      const currentCategory = newData[categoryIndex];
      newData[categoryIndex] = newData[categoryIndex + 1];
      newData[categoryIndex + 1] = currentCategory;
    }

    setData(newData);
  };

  return (
    <div style={{ padding: "20px" }}>
      {data.map((category, categoryIndex) => (
        <Card
          key={categoryIndex}
          title={
            <Space align="center">
              <Input
                value={category.category}
                onChange={(e) => {
                  const newData = [...data];
                  newData[categoryIndex].category = e.target.value;
                  setData(newData);
                }}
                style={{ fontWeight: "bold", fontSize: "18px" }}
              />
              <div style={{ display: "flex" }}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => moveCategory(categoryIndex, "up")}
                >
                  ⬆️
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => moveCategory(categoryIndex, "down")}
                >
                  ⬇️
                </div>
              </div>
              <EditOutlined style={{ cursor: "pointer" }} />
              <DeleteOutlined
                style={{ cursor: "pointer" }}
                onClick={() => deleteCategory(categoryIndex)}
              />
            </Space>
          }
          extra={
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => addQA(categoryIndex)}
            >
              Add QA
            </Button>
          }
          style={{ marginBottom: "20px" }}
        >
          {category.items.map((qa, qaIndex) => (
            <div key={qaIndex}>
              <Text
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {`Q${qaIndex + 1}: `}
                <div style={{ display: "flex" }}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => moveQA(categoryIndex, qaIndex, "up")}
                  >
                    ⬆️
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => moveQA(categoryIndex, qaIndex, "down")}
                  >
                    ⬇️
                  </div>
                </div>
              </Text>
              <Input
                value={qa.question}
                onChange={(e) => {
                  const newData = [...data];
                  newData[categoryIndex].items[qaIndex].question =
                    e.target.value;
                  setData(newData);
                }}
              />
              <Text strong>{`A${qaIndex + 1}: `}</Text>
              <Input.TextArea
                value={qa.answer}
                onChange={(e) => {
                  const newData = [...data];
                  newData[categoryIndex].items[qaIndex].answer = e.target.value;
                  setData(newData);
                }}
              />
              <div style={{ display: "flex", gap: "10px" }}>
                <div>
                  <Checkbox
                    checked={qa.onlypro}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[categoryIndex].items[qaIndex].onlypro =
                        e.target.checked;
                      setData(newData);
                    }}
                  />{" "}
                  only for pro
                </div>
                <div>
                  <Checkbox
                    checked={qa.onlyannual}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[categoryIndex].items[qaIndex].onlyannual =
                        e.target.checked;
                      setData(newData);
                    }}
                  />{" "}
                  only for annual versions
                </div>
                <div>
                  <Checkbox
                    checked={qa.hidevita}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[categoryIndex].items[qaIndex].hidevita =
                        e.target.checked;
                      setData(newData);
                    }}
                  />{" "}
                  hide in sustrax vita
                </div>
                <div>
                  <Checkbox
                    checked={qa.onlyvita}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[categoryIndex].items[qaIndex].onlyvita =
                        e.target.checked;
                      setData(newData);
                    }}
                  />{" "}
                  only for sustrax vita
                </div>
                <div style={{ alignSelf: "flex-end" }}>
                  (<DeleteOutlined
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteQA(categoryIndex, qaIndex)}
                  />{" "}
                  Delete{" "})
                </div>
              </div>
            </div>
          ))}
        </Card>
      ))}

      <Button type="dashed" icon={<PlusOutlined />} onClick={addCategory}>
        Add Category
      </Button>
    </div>
  );
}

const Faqconfig = () => {
  const [jsonObject, setJsonObject] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleTextChange = (e) => {
    const newText = e.target.value;

    try {
      const parsedJson = JSON.parse(newText);
      setJsonObject(parsedJson);
    } catch (error) {
      setJsonObject({ error: "Invalid JSON" });
    }
  };
  useEffect(() => {
    catcher(async () => {
      const faq = await getAppInfo("faq");
      setJsonObject(faq.data);
      setLoading(false);
    }, {});
  }, []);

  const handleUpdate = () => {
    catcher(
      async () => {
        if (jsonObject.error) {
          return message.error("Not correct!");
        }
        await updateAppInfo({ data: jsonObject }, "faq");
      },
      { setLoading }
    );
  };
  if (loading) return <Loading />;
  return (
    <div>
      <Editor data={jsonObject} setData={setJsonObject} />
      <Divider />
      {jsonObject && (
        <div style={{ padding: 10 }}>
          <Text strong>
            Here is how the page will look:
            <Button
              onClick={handleUpdate}
              loading={loading}
              style={{ background: "firebrick", color: "white" }}
            >
              Update Now
            </Button>
          </Text>
          {!jsonObject.error && (
            <div style={{ height: "400px", overflowY: "scroll" }}>
              <ErrorBoundary>
                <FAQPage data={jsonObject} />
              </ErrorBoundary>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Faqconfig;
