import { Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { openSites } from "../../../helper/attribute";
import { fixDecimals } from "../../../helper/wodash";
import { selectResults } from "../../../redux/features/appSlice";
import { findForm } from "../../../static/formRoutes";
import { getGHGs } from "../../../static/ghg.static";
import Boxed from "./comp/boxed";
import Graph from "./comp/graph";
import Heading from "./comp/heading";
import Options from "./comp/options";

import "./style.css";
import { getGHGAnalysis } from "./workers/ghg";
import YearAnalysis from "./year.analysis";
import commaNumber from "comma-number";

const columns = [
  {
    title: "Category",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Emissions",
    align: "right",
    dataIndex: "result",
    key: "result",
    render: (_) => commaNumber(fixDecimals(_)), // remove if export is not working fine
  },
];

const GHGAnalysis = () => {
  const [options, setOptions] = useState({
    year: 2025,
    wtt: "Included",
    type: "Location",
    reporting: "All",
  });
  const [dataSource, setDataSource] = useState(undefined);
  const results = useSelector(selectResults);
  useEffect(() => {
    setDataSource(undefined);
    if (results)
      setDataSource(getGHGAnalysis(results, getGHGs(), options)?.dataSource);
  }, [results, options]);
  const expandedRowRender = (record) => {
    const childColumns = [
      {
        title: "Type",
        dataIndex: "id",
        render: (_) =>
          (_.includes("wtt") ? "WTT " : "") +
          findForm(_.replace("wtt-", ""))?.title,
      },
      ...openSites().map((site, i) => ({
        key: site.id + i,
        dataIndex: site.id,
        title: site.title,
        render: (_) =>
          fixDecimals(_) || <span style={{ color: "lightgrey" }}>-</span>,
      })),
    ];
    return (
      <div style={{ maxWidth: "78vw" }}>
        <Table
          scroll={{ x: 400 }}
          columns={childColumns}
          dataSource={record.children} // Single child record for demonstration
          pagination={false}
        />
      </div>
    );
  };
  return (
    <div>
      <Heading type={"GHG Analysis"} />
      <Options options={options} setOptions={setOptions} />
      <Graph
        dataSource={dataSource}
        hidden={["scope", "pie scope"]}
        typeOfChart="GHG Category"
      />
      <Boxed title={"Analysis"}>
        <Table
          // scroll={{ x: 400 }}
          pagination={false}
          loading={!dataSource || !results}
          dataSource={dataSource}
          columns={columns}
          expandable={{ expandedRowRender }}
          rowKey={(record, i) => i + "-row"}
          key="id"
        />
      </Boxed>
      <YearAnalysis metricsOnly />
    </div>
  );
};

export default GHGAnalysis;
