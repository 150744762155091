import React, { useState, useEffect } from "react";
import { Modal, Button, Spin, message } from "antd";
import { CloseCircleOutlined, CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { selectResults, setResults } from "../../redux/features/appSlice";
import { getResultDates,  } from "../../pages/results/resultsCalcs";
import { getCurrentDate, getCurrentYear, timeFormat } from "../../helper/time";
import { getCurrentPlan } from "../../helper/plans";
import { routePath } from "../../static/routes.static";
import moment from "moment";
import { bulkUpdateRows } from "../../pages/results/recalculate-without-modal";










export const updateDateToCurrentYear = (result) => ({
  ...result,
  date: moment(result.date, timeFormat).year(getCurrentYear()).format(timeFormat),
});





//NOT USING IT NOW


const UpdateResultsTo2025 = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const results = useSelector(selectResults);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const isFreePlan = getCurrentPlan() === "free";

  const resultsPrevious =
    results?.filter((result) => {
      return getResultDates(result).calendarYear < getCurrentYear();
    }) || [];

  useEffect(() => {
    if (
      isFreePlan &&
      resultsPrevious.length > 0 &&
      location.pathname !== routePath.Upgrade
    ) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [location.pathname, isFreePlan, resultsPrevious.length]);

  const handleMoveResults = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const updatedResults = resultsPrevious.map((result) => ({
        ...result,
        date: moment(result.date, timeFormat).year(2025).format(timeFormat),
      }));

      await bulkUpdateRows(updatedResults, dispatch, setIsLoading);

      message.success("Results successfully moved to 2025!");
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error updating results:", error);
      setIsError(true);
      message.error("Failed to move results to 2025. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        title={
          <span style={{ fontSize: "28px", display: "flex", alignItems: "center" }}>
            <WarningOutlined style={{ color: "#faad14", marginRight: "12px" }} />
            Attention Required: Update Your Results
          </span>
        }
        open={isModalVisible}
        closable={false}
        footer={null}
        width={800} // Enlarged modal width
        bodyStyle={{ padding: "32px" }} // Spacious body padding
      >
        <div style={{ textAlign: "center" }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "24px" }}>
            <div style={{ marginRight: "24px" }}>
              <CloseCircleOutlined style={{ fontSize: "80px", color: "#ff4d4f" }} />
              <p style={{ fontSize: "20px", fontWeight: "bold", marginTop: "8px" }}>2024</p>
            </div>
            <div style={{ marginLeft: "24px" }}>
              <CheckCircleOutlined style={{ fontSize: "80px", color: "#52c41a" }} />
              <p style={{ fontSize: "20px", fontWeight: "bold", marginTop: "8px" }}>2025</p>
            </div>
          </div>
          <p style={{ fontSize: "18px", lineHeight: "1.8", marginBottom: "32px" }}>
            You have older results from 2024, and the free plan only allows access to results within a single year, i.e., 2025. Please
            <Link to={routePath.Upgrade} style={{ textDecoration: "none", fontWeight: "bold", margin: "0 5px" }}>
              upgrade your plan
            </Link>
            or move the data to the current year. This change won't affect the calculations; it will only update the date to 2025.
          </p>
          <div style={{ marginTop: "32px" }}>
            {isLoading ? (
              <Spin tip="Updating results..." />
            ) : (
              <Button type="primary" size="large" onClick={handleMoveResults}>
                Move Results to 2025
              </Button>
            )}
          </div>
          {isError && (
            <p style={{ color: "red", marginTop: "16px" }}>
              An error occurred while updating results. Please try again.
            </p>
          )}
        </div>
      </Modal>
    </>
  );
};

export default UpdateResultsTo2025;
